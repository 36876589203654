import specialAreas from '../../../../config/special_areas.yml'

const specialSyms = Object.keys(specialAreas)
const result = specialSyms.reduce((data, key) => {
  const cur = specialAreas[key]
  const { names, className } = cur

  data.specialNames[key] = names
  names.forEach(name => {
    data.inverseNames[name] = key
  })
  data.classNames[key] = className

  return data
}, { specialNames: {}, inverseNames: {}, classNames: {} })

const { specialNames, inverseNames, classNames } = result

export function specialFromName(areaName) {
  return inverseNames[areaName]
}

export function classFromSpecial(special) {
  return classNames[special]
}

export function classFromAreaName(areaName) {
  const special = specialFromName(areaName)
  return classFromSpecial(special)
}

// If it's not special then it's provider
export function isProviderFromName(areaName) {
  return areaName ? !specialFromName(areaName) : false
}

export function isStore(areaName) {
  return specialFromName(areaName) == 'store'
}
