import React from 'react'
import Flex from '@react-css/flex'

import { Button, Well } from '../bootstrap'
import { Search } from '../ui/Search'
import { Pagination } from '../ui/Pagination'
import { tr } from '../translator'
import { FilterContainer } from './FilterContainer'
import { GenericExport } from './GenericExport'

import styles from './CrudView.module.sass'

export function CrudView({
  model,
  collection,
  loading,
  searchText,
  onSearchChange,
  onSearch,
  resultsComponent,
  editComponent,
  onDocChange,
  searchFormComponent,
  searchInputProps,
  page,
  count,
  onPageChange,
  perPage,
  resultsProps,
  relModelName,
  relId,
  children,
  noSearch,
  noFilter,
  noPagination,
  noExport,
  state,
  dispatch,

  ...rest
}) {
  const Results = resultsComponent

  const filterComponent = model.filter && model.filter.ui
  const ExportComponent = model.export && model.export.ui || GenericExport

  return (
    <>
      {children}
      <Flex row noWrap alignItemsStart>
        <Flex column noWrap>
          {
            !noSearch &&
              <Search
                name={`${model.name}_index`}
                search={searchText}
                onChange={onSearchChange}
                onSearch={onSearch}
                relModelName={relModelName}
                relId={relId}
                {...searchInputProps}
              />
          }
          {
            !noPagination &&
              <Pagination
                page={page - 1}
                total={count}
                perPage={perPage}
                maxShow={perPage}
                onPageChange={onPageChange}
                noMargin={noSearch}
                relModelName={relModelName}
                relId={relId}
              />
          }
        </Flex>
        {
          !noExport &&
            <div className={styles.export}>
              <ExportComponent
                relModelName={relModelName}
                relId={relId}
                model={model}
              />
            </div>
        }
        {
          !noFilter && filterComponent &&
            <FilterContainer
              state={state}
              dispatch={dispatch}
              component={filterComponent}
              model={model}
            />
        }
      </Flex>
      <Results
        {...rest}
        model={model}
        collection={collection}
        resultsProps={resultsProps}
      />
    </>
  )
}
