import classnames from 'classnames'

import { tr } from '../translator'

import styles from './Pagination.module.sass'

const ItemContent = ({
  text,
  aria
}) => {
  if (aria) {
    return (
      <span aria-hidden="true">
        {text}
      </span>
    )
  } else
    return text
}

const Item = ({
  onClick,
  text,
  aria,
  active,
  disabled
}) =>
  <li onClick={onClick} className={classnames('page-item', { active, disabled })}>
    <a
      href="#"
      className="page-link"
      aria-label={aria}
      data-turbolinks="false"
      data-key={`page-${text}`}
    >
      <ItemContent text={text} aira={aria} />
    </a>
  </li>

export const Pagination = ({
  page,
  total,
  perPage = 10,
  maxShow = 10,
  onPageChange,
  noMargin,
  relModelName,
  relId
}) => {
  const handlePrevious = () => {
    const newPage = page - 1

    onPageChange(newPage < 0 ? 0 : newPage)
  }

  const handleFirst = () => {
    onPageChange(0)
  }

  const handleLast = () => {
    const last = parseInt(total / perPage)
    onPageChange(last)
  }

  const handleNext = () => {
    if (page < lastPage()) {
      const newPage = page + 1
      onPageChange(newPage >= total ?  total - 1 : newPage)
    }
  }

  const gotoPage = (toPage) => {
    onPageChange(toPage)
  }

  const renderPage = (pageToRender) => {
    const active = pageToRender == page

    return (
      <Item
        key={pageToRender}
        onClick={() => gotoPage(pageToRender)}
        text={pageToRender + 1}
        active={active}
      >
        {pageToRender + 1}
      </Item>
    )
  }

  const lastPage = () => {
    return parseInt(total / perPage)
  }

  const displayPage = () => {
    return page + 1
  }

  const displayTotalPages = () => {
    return lastPage() + 1
  }

  const renderLiterature = () => {
    if (total > 0) {
      return tr('pagination.showing', {
        page: displayPage(),
        total: displayTotalPages(),
        documents: total
      })
    } else {
      return tr('pagination.no_results')
    }
  }

  const renderPages = () => {
    const havePages = total / perPage

    const num = Math.min(havePages, maxShow)
    const side = parseInt(num / 3)
    const last = lastPage()

    const firstShow = Math.max(0, page - side)
    const lastShow = Math.min(last, page + side)

    let renderedPages = []
    if (firstShow > 0)
      renderedPages.push(renderPage(0))
    if (firstShow == 2)
      renderedPages.push(renderPage(1))
    if (firstShow > 2)
      renderedPages.push(<Item key="elip-first" text="…" disabled />)

    for (let i = firstShow; i <= lastShow; i++) {
      renderedPages.push(renderPage(i))
    }

    const lastDiff = last - lastShow
    if (lastDiff > 2)
      renderedPages.push(<Item key="elip-last" text="…" disabled />)
    if (lastDiff == 2)
      renderedPages.push(renderPage(last - 1))
    if (lastDiff > 0)
      renderedPages.push(renderPage(last))

    return renderedPages
  }

  const renderPagination = () => {
    const last = lastPage()

    if (total > 0) {
      return (
        <nav aria-label={tr('pagination.title')}>
          <ul>
            <Item
              key="first"
              onClick={handleFirst}
              text="«"
              aria={tr('pagination.first')}
            />
            <Item
              key="prev"
              onClick={handlePrevious}
              text="‹"
              aria={tr('pagination.previous')}
              disabled={page == 0}
            />
            {renderPages()}
            <Item
              key="next"
              onClick={handleNext}
              text="›"
              aria={tr('pagination.next')}
              disabled={page == last}
            />
            <Item
              key="last"
              onClick={handleLast}
              text="»"
              aria={tr('pagination.last')}
            />
          </ul>
        </nav>
      )
    } else {
      return null
    }
  }

  const containerClasses = classnames('pagination', {
    [styles.noMargin]: noMargin
  })

  const classes = classnames(styles.literature, {
    [styles.empty]: total == 0
  })

  return (
    <div className={containerClasses}>
      {renderPagination()}
      <div className={classes}>
        {renderLiterature()}
      </div>
    </div>
  )
}
