import Flex from '@react-css/flex'

import { FormHorizontal, NumberInput } from '../bootstrap'
import { PriceTaxedEdit } from '../area_arts/PriceTaxedEdit'
import { priceRead, applyDiscount } from '../utils/money'
import { tr } from '../translator'
import { getTax } from '../utils/movimientos'

// We directly get taxName and taxFactor beacuse we sometimes want
// to show a complex tax ("3,14% impuestos")
export const DiscountEditDest = ({
  descuento,
  taxName,
  taxFactor,
  raw,
  procesado,
  cantidad = 1,
  onChange,
  ...rest
}) => {
  const changeWantedPrice = (data) => {
    const rawNumeric = priceRead(data.raw)
    if (rawNumeric && onChange)
      onChange((1 - rawNumeric / (raw * cantidad)) * 100)
  }


  const discountedRaw = applyDiscount(descuento, raw)
  const discountedProcesado = applyDiscount(descuento, procesado)

  return (
    <FormHorizontal>
      <Flex column noWrap>
        <NumberInput
          key={0}
          value={descuento}
          onChange={onChange}
          wantNumber
          label={tr('vetus.movimientos.discount')}
        />
        <PriceTaxedEdit
          raw={discountedRaw * cantidad}
          procesado={discountedProcesado * cantidad}
          taxName={taxName}
          taxFactor={taxFactor}
          initialRaw={raw}
          initialProcesado={procesado}
          onChange={changeWantedPrice}
        />
      </Flex>
    </FormHorizontal>
  )
}
