import { useState } from 'react'
import classnames from 'classnames'

import {
  FormHorizontal,
  Button,
  Badge,
  Table
} from '../bootstrap'
import Select from 'react-select'
import { AreaSelect } from '../areas/AreaSelect'
import { DetailedPrice } from '../ui/DetailedPrice'
import Flex from '@react-css/flex'
import { ViewWrap } from '../application/ViewWrap'
import { MovEntEdit } from './MovEntEdit'
import { DiscountEdit } from './DiscountEdit'
import { PaymentEdit } from './PaymentEdit'
import { priceToString } from '../utils/money'
import {
  calcMovimientoTotal,
  calcTotalDiscount,
  calcTaxCombinedFactor,
  prettyMultipleTaxName,
  movimientoSubmit,
  initialPaymentsFromMovimiento
} from '../utils/movimientos'
import { useMovEntsReducer } from '../utils/mov_ents_reducer'
import { navigateTo } from '../utils/http'

import { ClienteLink } from '../clients/ClientLink'
import {
  Input,
  Icon,
  Row
} from '../bootstrap'

import { getSelected } from '../utils/options_parse'
import { tr } from '../translator'
import { SubmitButton } from '../ui/SubmitButton'

const renderCliente = ({
  cliente
}) => {
  if (cliente)
    return <ClienteLink cliente={cliente} />
  else {
    return (
      <Badge className="warning" icon="exclamation">
        {tr('vetus.no_one')}
      </Badge>
    )
  }
}

export default ({
  movimiento,
  areas,
  origin,
  dest,
  isFactura,
  taxes,
  editing,
  movimientoTotal,
  storeArea
}) => {
  const { cliente } = movimiento

  const initialOrgs = getSelected(areas, origin)
  const [ areasOrg, setAreasOrg ] = useState(initialOrgs)
  const initialDest = getSelected(areas, dest)
  const [ areaDest, setAreaDest ] = useState(initialDest)
  const initialObs = movimiento && movimiento.obs || ''
  const [ observations, setObservations ] = useState(initialObs)
  const {
    movEnts,
    allMovEnts,
    updateMovEnt,
    removeMovEnt,
    movEntSetResults,
    movEntSelect
  } = useMovEntsReducer(movimiento.mov_ents)
  const [ discount, setDiscount ] = useState(movimiento.descuento || '')
  const initialPayments = initialPaymentsFromMovimiento(movimiento, movimientoTotal)
  const [ payments, setPayments ] = useState(initialPayments)

  console.log("MOVIMIENTO", movimiento)

  const handleDescuentoChange = (amount) => {
    setDiscount(amount)
  }

  const handleCancel = () => {
    navigateTo('/movimientos')
  }

  const sum = calcMovimientoTotal(movEnts, { isProvider: isFactura })
  const sumDiscounted = calcTotalDiscount(sum, discount)
  const taxCombinedFactor = calcTaxCombinedFactor(sum)
  const sumTaxName = prettyMultipleTaxName(sum)

  console.log("MOV SUM", sum)
  console.log("MOV DISCOUNTED", sumDiscounted)
  console.log("MOV TAX COMBINED", sumTaxName)

  const hasStuff = movEnts.reduce((hasStuff, cur) =>
    hasStuff || (cur.area_art && cur.area_art.id)
  , false)

  const handleSubmit = () => {
    movimientoSubmit({
      isFactura,
      clientId: cliente && cliente.id,
      observations,
      areaDest: areaDest && areaDest.value,
      movEnts: allMovEnts,
      discount,
      payments,
      sumDiscounted,
      movimientoId: movimiento.id
    })
  }

  return (
    <ViewWrap>
      <div className="movimiento">
        {
          !isFactura &&
            <dl className="dl-horizontal">
              <dt>{tr('activerecord.models.cliente.one')}</dt>
              <dd>{renderCliente({ cliente })}</dd>
            </dl>
        }
        <FormHorizontal
          action="/movimientos"
          method="post"
        >
          <Input
            inputComponent="textarea"
            label={tr('simple_form.labels.defaults.obs')}
            optional
            value={observations}
            onChange={setObservations}
            data-key="observations"
          />
          <Flex row noWrap alignItemsCenter>
            <AreaSelect
              id="movimiento-origin-areas"
              name="area_filter[area][]"
              value={areasOrg}
              onChange={setAreasOrg}
              options={areas}
              isMulti
            />
            <Icon className="chevron-right inline-item-margin" />
            <AreaSelect
              id="movimiento-dest-area"
              name="movimiento[area_id]"
              value={areaDest}
              onChange={setAreaDest}
              options={areas}
            />
          </Flex>
          <Table stripped condensed bordered>
            <thead>
              <tr key="head">
                <th>{tr('helpers.actions')}</th>
                <th>{tr('vetus.movimientos.origen')}</th>
                <th>{tr('vetus.movimientos.art')}</th>
                <th>{tr('vetus.movimientos.tax')}</th>
                {
                  isFactura &&
                    <th>{tr('vetus.mov_ents.price_prov')}</th>
                  ||
                    <th>{tr('vetus.mov_ents.price_ticket')}</th>
                }
                {
                  isFactura &&
                    <th>{tr('vetus.store_unit')}</th>
                }
                <th>{tr('vetus.movimientos.discount')}</th>
                <th>{tr('vetus.movimientos.amount')}</th>
                <th>{tr('vetus.movimientos.total')}</th>
              </tr>
            </thead>
            <tbody>
              {
                movEnts.map((movEnt, idx) => {
                  const { key } = movEnt

                  return (
                    <MovEntEdit
                      key={key}
                      idx={key}
                      movEnt={movEnt}
                      onChange={doc => updateMovEnt(key, doc)}
                      onRemove={doc => removeMovEnt(key)}
                      setResults={results => movEntSetResults(key, results)}
                      selectResult={(idx, doc) => movEntSelect(key, idx, doc)}
                      storeArea={storeArea}
                      areasOrg={areasOrg}
                      areaDest={areaDest}
                      taxes={taxes}
                      isFactura={isFactura}
                      index={idx}
                      numMovEnts={movEnts.length}
                    />
                  )
                })
              }

              {
                // Total without global discount
                hasStuff &&
                  <tr key="totals" className="tr-centered">
                    <td key="actions"></td>
                    <td key="origin"></td>
                    <td key="art"></td>
                    <td key="tax"></td>
                    {
                      isFactura &&
                        <td key="price"></td>
                    }
                    <td key="pvp"></td>
                    <td key="dis"></td>
                    <td key="amount">
                      <h4>{tr('vetus.movimientos.total')}</h4>
                    </td>
                    <td key="total">
                      <DetailedPrice
                        raw={sum.raw}
                        procesado={sum.procesado}
                        taxName={sumTaxName}
                      />
                    </td>
                  </tr>
              }
              {
                // Global discount and total discounted
                hasStuff &&
                  <tr key="discount" className="tr-centered">
                    <td key="actions"></td>
                    <td key="origin"></td>
                    <td key="art"></td>
                    <td key="tax"></td>
                    {
                      isFactura &&
                        <td key="price"></td>
                    }
                    <td key="pvp">
                      <h4>{tr('vetus.movimientos.total_discount')}</h4>
                    </td>
                    <td key="dis">
                      <DiscountEdit
                        descuento={discount}
                        raw={sum.raw}
                        procesado={sum.procesado}
                        onChange={handleDescuentoChange}
                        taxName={sumTaxName}
                        taxFactor={taxCombinedFactor}
                      />
                    </td>
                    <td key="amount">
                      {
                        discount &&
                          <h4>{tr('vetus.movimientos.total_discounted')}</h4>
                      }
                    </td>
                    <td key="total">
                      {
                        discount &&
                          <DetailedPrice
                            raw={sumDiscounted.raw}
                            procesado={sumDiscounted.procesado}
                            taxName={sumTaxName}
                          />
                      }
                    </td>
                  </tr>
              }
            </tbody>
          </Table>

          {
            !isFactura && hasStuff &&
              <PaymentEdit
                payments={payments}
                onChange={setPayments}
                total={sumDiscounted.procesado}
              />
          }

          {
            hasStuff &&
              <div className="form-actions">
                <SubmitButton onClick={handleSubmit} />
                <Button
                  key="cancel"
                  icon="remove"
                  text={tr('helpers.links.cancel')}
                  onClick={handleCancel}
                />
              </div>
          }
        </FormHorizontal>
      </div>
    </ViewWrap>
  )
}
