import { AreaLabel } from '../areas/AreaLabel'
import { tr } from '../translator'

export const TaxDisplay = ({ name, origin, isForcedTax }) => {
  if (isForcedTax) {
    return (
      <>
        <span>{`${name} (${tr('vetus.movimientos.tax_forced')})`} </span>
      </>
    )
  } else if (origin) {
    return (
      <>
        <span>{`${name} (${tr('vetus.movimientos.tax_from')} `} </span>
        <AreaLabel areaName={origin} />
        <span>)</span>
      </>
    )
  } else {
    return `${name} (${tr('vetus.movimientos.tax_from_own')})`
  }
}
