import { Well, Button } from '../bootstrap'
import { tr } from '../translator'

import styles from './FilterContainer.module.sass'

export const FilterContainer = ({
  state,
  dispatch,
  component
}) => {
  const $filterUI = component

  const handleReset = () => {
    if (model.filters)
      dispatch({ type: 'filter-reset' })
  }

  return (
    <Well className={styles.container} label={tr('vetus.filtering.filters')}>
      <$filterUI state={state} dispatch={dispatch} />
      <Button
        icon="refresh"
        text={tr('vetus.filtering.remove_filters')}
        onClick={handleReset}
      />
    </Well>
  )
}
