export const Impuesto = {
  indexPath: '/impuestos',

  fields: {
    name: {
      type: 'name'
    },
    display: {
      type: 'string'
    },
    cantidad: {
      type: 'number'
    }
  },

  modelName: 'impuesto',

  namePath: 'name'
}
