import { LinkToResource } from './LinkToResource'
import { getModel } from '../../models'

export const DisplayAssociated = ({
  fieldSchema,
  value = null,
  contents
}) => {
  if (value) {
    const otherModel = getModel(fieldSchema.otherClass)

    return (
      <LinkToResource
        model={otherModel}
        doc={value}
        contents={contents}
      />
    )
  } else
    return null
}
