import { useState } from 'react'
import classnames from 'classnames'

import { Input, Button } from '../bootstrap'
import { positiveMod } from '../utils/utils'
import { tr } from '../translator'
import { useEscape } from '../hooks/useEscape'
import { useAccumulativeDebouncer } from '../hooks/useAccumulativeDebouncer'

import styles from './AssociatedSearch.module.sass'

function ReplyLi ({
  entry,
  idx,
  selected,
  onSelect,
  onHover,
  children
}) {
  const liClass = classnames({ selected: selected })

  return (
    <li
      onClick={() => onSelect(idx)}
      onMouseEnter={() => onHover(idx)}
      className={liClass}
    >
      {children}
    </li>
  )
}

export const AssociatedSearch = ({
  name, // for tests
  value,
  searching,
  locked,
  results = [],
  onChange,
  setResults,
  selectResult,
  querier,
  renderer = (entry, highlighted) => highlighted,
  keyer = entry => entry.id,
  namer = entry => entry.name,
  highlighter = entry => entry.name,
  className
}) => {
  const [ selectedIdx, setSelectedIdx ] = useState(0)

  const setSearching = text => onChange({ searching: text })

  const isSearching = searching !== value
  const hasResults = results.length > 0

  const inputClasses = classnames(styles.input, {
    [styles.success]: locked && !isSearching
  })

  const selectResultByIndex = (idx, handledResults = []) => {
    const result = handledResults[idx] || results[idx]
    if (result && result.doc)
      selectResult(idx, result.doc)
  }

  const doKeys = (keyCode, newResults) => {
    // console.log("KEY CODE", keyCode)

    switch (keyCode) {
    case 'Tab':
    case 'Enter':
    case 'NumpadEnter':
      if (newResults && newResults.length == 1)
        selectResultByIndex(0, newResults)
      else if (hasResults)
        selectResultByIndex(selectedIdx)
      break
    }
  }

  // Debounced
  const doSearch = async ({ value, keyCode }) => {
    // console.log("DO SEARCH", value, keyCode)

    let searchResults
    if (typeof value != 'undefined') {
      searchResults = await querier(value)
      setResults(searchResults)
      setSelectedIdx(0)
    }

    if (keyCode) {
      doKeys(keyCode, searchResults)
    }
  }

  const {
    updateCallback,
    send: debounceAndSend
  } = useAccumulativeDebouncer(doSearch)

  // As new renders come in, doSearch will be created again with
  // different closure, we update the debounced search
  // without touching any other debounce logic
  updateCallback(doSearch)

  const handleSearch = (value) => {
    setSearching(value)
    debounceAndSend({ value })
  }
  const handleKeys = (keyEvent) => {
    switch (keyEvent.key) {
    case 'ArrowDown':
      {
        const newIdx = (selectedIdx + 1) % results.length
        setSelectedIdx(newIdx)
        keyEvent.preventDefault()
      }
      break
    case 'ArrowUp':
      {
        const newIdx = positiveMod(selectedIdx - 1, results.length)
        setSelectedIdx(newIdx)
        keyEvent.preventDefault()
      }
      break
    case 'Tab':
    case 'Enter':
    case 'NumpadEnter':
      keyEvent.preventDefault()
      debounceAndSend({ keyCode: keyEvent.code })
    }
  }

  const handleSelect = (idx) => {
    const entry = results[idx]
    handleSelectWithEntry(entry)
  }

  const handleCancel = () => {
    selectResult()
  }

  useEscape(handleCancel)

  return (
    <div className={className}>
      <Input
        name={name}
        inline
        onChange={handleSearch}
        onKeyDown={handleKeys}
        className={inputClasses}
        value={searching}
        autoFocus
        noAutocomplete
      />
      { isSearching && hasResults &&
          <Button
            icon="remove"
            onClick={handleCancel}
            text={tr('helpers.links.close')}
          />
      }
      {
        isSearching && hasResults &&
          <ul className="search-overlay">
            {
              results.map((entry, idx) => {
                const key = keyer(entry) || idx
                const label = namer(entry)
                const highlighted = () =>
                  <span dangerouslySetInnerHTML={{
                    __html: highlighter(entry)
                  }} />

                return (
                  <ReplyLi
                    key={key}
                    entry={entry}
                    idx={idx}
                    onSelect={() => selectResultByIndex(idx)}
                    onHover={setSelectedIdx}
                    selected={selectedIdx == idx}
                  >
                    {renderer(entry, highlighted)}
                  </ReplyLi>
                )
              })
            }
          </ul>
      }
    </div>
  )
}
