import { forwardRef } from 'react'
import { Autoform as RHFAutoform } from 'react-hook-form-auto'
export { createSchema } from 'react-hook-form-auto'
import { skin } from './autoformSkin.js'

const styles = {
  inputBlockWrap: 'form-group',
  label: '',
  input: 'form-control',
  standard: '',
  errored: 'is-invalid',
  inlineWrap: 'form-group',
  itemHeader: '',
  button: 'btn btn-primary',
  checkboxWrap: 'form-check',
  radioGroupWrap: '',
  radioWrap: 'form-check',
  checkboxLabel: 'form-check-label',
  radioLabel: 'form-check-label',
  radio: 'form-check-input',
  checkbox: 'form-check-input',
  error: 'invalid-feedback',
  errorIcon: '',
  select: 'form-control',
  table: 'table',
  panel: 'card',
  title: 'card-header',
  content: 'card-body',
  contentMargin: ''
}

export let Autoform = (props, ref) =>
  <RHFAutoform
    {...props}
    styles={styles}
    skinOverride={skin}
    ref={ref}
  />

Autoform = forwardRef(Autoform)
