// We do it module level until we move all javascript to react
let envValue

export function setEnv(env) {
  envValue = env
}

export function isEnv(check) {
  return envValue == check
}

export function isDevelopment() {
  return isEnv('development')
}

export function isTest() {
  return isEnv('test')
}

