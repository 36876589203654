import { useRef, useState, useEffect, forwardRef } from 'react'

import classnames from 'classnames'

import { Wrapper } from './Wrapper'

export let Input = ({
  id,
  name,
  type = 'text',
  inputComponent = 'input',
  inputNode,
  label,
  className,
  optional,
  inline,
  onChange,
  buttonAddOn,
  addOn,
  value,
  uncontrolled,
  autoFocus,
  noAutocomplete,
  ...rest
}, ref) => {
  const [ selection, setSelection ] = useState({ start: false, end: false })
  const inputRef = useRef()
  useEffect(() => {
    const elem = inputRef.current
    if (elem) {
      const selectable =
        elem.type != 'hidden' &&
        elem.type != 'number' &&
        elem.type != 'file'

      if (elem && selectable) {
        inputRef.current.selectionStart = selection.start
        inputRef.current.selectionEnd = selection.end
      }
    }
  }, [ selection.start, selection.end ])
  useEffect(() => {
    if (autoFocus)
      inputRef.current && inputRef.current.focus()
  }, [])

  const InputComponent = inputComponent || 'input'

  const inputClasses = classnames(className, {
    'input-small': inline
  })

  const handleChange = e => {
    const input = inputRef.current
    const cur = {
      start: input.selectionStart,
      end: input.selectionEnd
    }
    if (cur.start != selection.start || cur.end != selection.end)
      setSelection({ start: cur.start, end: cur.end })

    e.preventDefault()
    if (onChange) {
      if (type == 'file')
        onChange(e.target.files[0])
      else
        onChange(e.target.value)
    }
  }

  const valueIfAny = !uncontrolled && { value: value || '' }

  inputNode = inputNode || (
    <InputComponent
      {...rest}
      type={type}
      className={inputClasses}
      id={id}
      name={name}
      onChange={handleChange}
      ref={typeof InputComponent != 'function' ? inputRef : null}
      autoComplete={noAutocomplete && 'off'}
      {...valueIfAny}
    />
  )

  let addonedNode
  if (addOn) {
    addonedNode = (
      <div className="input-append">
        {inputNode}
        <span className="add-on">{addOn}</span>
      </div>
    )
  } else if (buttonAddOn) {
    addonedNode = (
      <div className="input-append">
        {inputNode}
        {buttonAddOn}
      </div>
    )
  } else
    addonedNode = inputNode

  if (inline) {
    return addonedNode
  } else {
    return (
      <Wrapper
        optional={optional}
        label={label}
      >
        {addonedNode}
      </Wrapper>
    )
  }
}

Input = forwardRef(Input)
