import classnames from 'classnames'

import { Icon } from './Icon'

export const Alert = ({
  className,
  title,
  children,
  block,
  warnIcon
}) => {
  const classes = classnames(
    className,
    'alert',
    { 'alert-block': block }
  )

  if (warnIcon) {
    title = (
      <Icon icon="warning-sign" />
    )
  }

  return (
    <div className={classes}>
      <button type="button" className="close" data-dismiss="alert">
        &times;
      </button>
      {
        title &&
          <h4>{title}</h4>
      }
      {children}
    </div>
  )
}
