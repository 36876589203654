import { DataShowContainer, DataShow, Well } from '../bootstrap'
import { tr } from '../translator'
import plural from 'pluralize-es'

import { DetailedPriceBoxable } from '../ui/DetailedPriceBoxable'
import { AreaLabel } from '../areas/AreaLabel'
import { TaxDisplay } from './TaxDisplay'
import { isProviderFromName } from '../utils/special_areas'
import { getTax } from '../utils/movimientos'

import styles from './AreaArtShowContext.module.sass'

export const AreaArtShowContext = ({
  areaArt = {},
  art = {},
  area = {},
  forceTax
}) => {
  const isProvider = isProviderFromName(area.name)
  const { subdiv } = art
  const isBoxable = !isProvider && subdiv && subdiv > 1
  const showBox = !isProvider && isBoxable && areaArt && areaArt.raw_box > 0

  const { tax, isForcedTax, isOwnTax } = getTax(areaArt, area, forceTax)
  const taxOrigin = !isOwnTax && area.name
  const subdivStr = subdiv &&
    `${subdiv} ${art.unidad && plural(art.unidad) || ''}`

  const precioDisplay = (
    <div className={styles.precio}>
      <DetailedPriceBoxable
        raw={areaArt && areaArt.raw}
        procesado={areaArt && areaArt.procesado}
        taxName={tax.name}
        rawOverProcessed={isProvider}
        subdiv={subdiv}
      />
    </div>
  )

  const precioBoxDisplay = showBox && (
    <div className={styles.precio}>
      <DetailedPriceBoxable
        raw={areaArt && areaArt.raw_box}
        procesado={areaArt && areaArt.procesado_box}
        taxName={tax.name}
        rawOverProcessed={isProvider}
        subdiv={subdiv}
      />
    </div>
  )

  return (
    <Well>
      <DataShowContainer compact>
        <DataShow
          key="art"
          label={tr('vetus.movimientos.art')}
          data={art.name}
        />
        <DataShow
          key="area"
          label={tr('vetus.fact.area')}
          data={<AreaLabel areaName={area.name} />}
        />
        <DataShow
          key="price"
          label={tr('vetus.movimientos.price')}
          data={precioDisplay}
        />
        {
          tax.name &&
            <DataShow
              key="tax"
              label={tr('activerecord.models.impuesto.one')}
              data={
                <TaxDisplay
                  name={tax.name}
                  origin={taxOrigin}
                  isForcedTax={isForcedTax}
                />
              }
            />
        }
        <DataShow
          key="price-box"
          label={tr('vetus.movimientos.price_box_short')}
          data={precioBoxDisplay}
        />
        {
          subdiv && subdiv > 1 &&
            <DataShow
              key="subdiv"
              label={tr('simple_form.labels.art.subdiv')}
              data={subdivStr}
            />
        }
      </DataShowContainer>
    </Well>
  )
}
