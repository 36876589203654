import classnames from 'classnames'
import { Icon } from '../../bootstrap'

const DEF_WORDS = 8

export const DisplayStringCollapse = ({
  model,
  fieldSchema,
  fieldName,
  value
}) => {
  const { words = DEF_WORDS } = fieldSchema

  return value
}
