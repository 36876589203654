import Flex from '@react-css/flex'

import { Button, NumberInput, FormHorizontal } from '../bootstrap'
import { useState } from 'react'
import { arrayReplace } from '../utils/utils'
import { priceRead, inputPriceToString } from '../utils/money'

// More than that and it will overflow popover. Also
// it wouldn't help, would it? Two discounts is already mad.
// Anyway, easily solvable by not using noWrap in Flex.
const maxDiscounts = 4

export const DiscountEditMultiple = ({
  discount,
  onChange,
  ...rest
}) => {
  const [ discounts, setDiscounts ] = useState([ discount || '0', '0' ])

  const handleDescuento = (newDescuento, idx = 0) => {
    if (discounts.length > 0) {
      const newDiscounts = arrayReplace(discounts, idx, newDescuento)
      setDiscounts(newDiscounts)

      const sumFactor = newDiscounts.reduce((acc, cur) => {
        const percent = priceRead(cur)
        if (percent)
          return acc * (1 - percent / 100)
        else
          return acc
      }, 1)

      const sumDescuento = inputPriceToString((1 - sumFactor) * 100)
      onChange(sumDescuento)
    } else {
      onChange(newDescuento)
    }
  }

  const handleAdd = () => {
    if (discounts.length < maxDiscounts)
      setDiscounts([ ...discounts, '0' ])
  }

  return (
    <FormHorizontal condensed component="div">
      <Flex row noWrap>
        {
          discounts.map((cur, idx) =>
            <NumberInput
              key={idx}
              value={discounts[idx]}
              onChange={dis => handleDescuento(dis, idx)}
              autoFocus={idx == 0}
              inline
            />
          )
        }
        <Button
          icon="plus"
          onClick={handleAdd}
          small
          disabled={discounts.length >= maxDiscounts}
        />
      </Flex>
    </FormHorizontal>
  )
}
