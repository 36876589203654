export const Section = {
  indexPath: '/sections',

  fields: {
    name: {
      type: 'name'
    },
    soldable: {
      type: 'boolean'
    }
  },

  modelName: 'section',
  namePath: 'name'
}

