export const DisplayTax = ({
  doc,
  value = null
}) => {
  if (value) {
    return (
      value.name
    )
  }

  return null
}

