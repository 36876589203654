import { forwardRef } from 'react'
import classnames from 'classnames'

import { Input } from './Input'
import { inputPriceToString, priceRead } from '../../utils/money'

export let NumberInput = ({
  value,
  onChange,
  wantNumber,
  className,
  ...rest
}, ref) => {
  const valueS = inputPriceToString(value)
  const classes = classnames(className, 'number-input')

  const handleNumber = newValue => {
    const asNumber = priceRead(newValue) || 0
    onChange(asNumber)
  }

  return (
    <div ref={ref}>
      <Input
        {...rest}
        className={classes}
        value={valueS}
        onChange={wantNumber ? handleNumber : onChange}
      />
    </div>
  )
}

NumberInput = forwardRef(NumberInput)
