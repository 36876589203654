import classnames from 'classnames'

import './FormHorizontal.sass'

export const FormHorizontal = ({
  children,
  condensed,
  component = "form",
  ...rest
}) => {
  const classes = classnames('form', 'form-horizontal', {
    'form-condensed': condensed
  })
  const $component = component

  return (
    <$component className={classes} {...rest}>
      {children}
    </$component>
  )
}
