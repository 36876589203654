import classnames from 'classnames'

export const Navbar = ({ className, children }) => {
  const classes = classnames(className, 'nav', 'nav-tabs')

  return (
    <ul className={classes}>
      {children}
    </ul>
  )
}

export const NavbarItem = ({
  selected,
  children,
  onClick
}) => {
  const classes = classnames({ active: selected })

  const handleClick = e => {
    e.preventDefault()
    if (onClick)
      onClick()
  }

  return (
    <li onClick={handleClick} className={classes}>
      <a href="#">
        {children}
      </a>
    </li>
  )
}
