import { useReducer } from 'react'

const FIRST_PAGE = 1

const initialState = {
  search: '',
  searchInput: '',
  page: FIRST_PAGE,
  firstFetch: true
}

function reducer(state, action) {
  switch (action.type) {
  case 'searchInput':
    return {
      ...state,
      searchInput: action.searchInput
    }
  case 'search':
    return {
      ...state,
      search: action.search,
      firstFetch: false,
      page: FIRST_PAGE
    }
  case 'setPage':
    return {
      ...state,
      page: action.page,
      firstFetch: false
    }
  default:
    return state
  }
}

export function useCrudReducer(model) {
  const { filter } = model

  const finalReducer = filter && filter.reducer ?
    filter.reducer(reducer) : reducer
  const finalInitialState = filter && filter.initial ?
    filter.initial(initialState) : initialState

  return useReducer(finalReducer, finalInitialState)
}
