import { useReducer } from 'react'
import {
  arrayLast,
  inverseSlice,
  arrayReplace,
  deepmerge
} from './utils'
import { railsToReactAreaArt } from './movimientos'

function keyToIndex(state, key) {
  return state.movEnts.reduce((found, cur, index) => {
    if (cur.key == key)
      return index
    else
      return found
  }, -1)
}

function movEntFromIndex(state, idx) {
  return state.movEnts[idx]
}

function keyToMovEnt(state, key) {
  const idx = keyToIndex(state, key)
  return movEntFromIndex(state, idx)
}

// Adds mov_ent updating keys
function addMovEnt(state, movEnt) {
  return {
    lastKey: state.lastKey + 1,
    movEnts: [
      ...state.movEnts,
      {
        ...movEnt,
        key: state.lastKey
      }
    ]
  }
}

// Returns a new state with blank added whenever it's necessary
function withBlank(state) {
  if (state.movEnts.length == 0) {
    return addMovEnt(state, {})
  } else {
    const lastME = arrayLast(state.movEnts)
    if (lastME.area_art && lastME.area_art.id)
      return addMovEnt(state, {})
    else
      return state
  }
}

function updateMovEnt(state, key, doc) {
  const { movEnts } = state

  const idx = keyToIndex(state, key)
  const current = movEnts[idx]

  // Just replacing data, not adding article
  return deepmerge({}, state, {
    movEnts: arrayReplace(movEnts, idx, deepmerge({}, current, doc))
  })
}

function removeMovEnt(state, key) {
  const idx = keyToIndex(state, key)
  const affected = movEntFromIndex(state, idx)

  if (affected.id) {
    return updateMovEnt(state, key, {
      ...affected,
      _destroy: true
    })
  } else {
    const deletedState = {
      ...state,
      movEnts: inverseSlice(state.movEnts, idx)
    }

    return withBlank(deletedState)
  }
}

function movEntSetResults(state, key, results) {
  const idx = keyToIndex(state, key)
  const movEnt = movEntFromIndex(state, idx)
  const movEntWithResults = {
    ...movEnt,
    results
  }

  return withBlank({
    ...state,
    movEnts: arrayReplace(state.movEnts, idx, movEntWithResults)
  })
}

function movEntSelectResult(state, key, resultIdx, result) {
  const { movEnts } = state
  const movEntIdx = keyToIndex(state, key)
  const movEnt = movEntFromIndex(state, movEntIdx)
  const { results } = movEnt

  let newMovEnts
  if (resultIdx >= 0) {
    result = (result && { doc: result }) || results[resultIdx] || {}
    const doc = result.doc || {}
    const { art, area, area_art } = railsToReactAreaArt(doc)
    const artName = doc.art && doc.art.name || ''

    const curAreaArt = movEnt.area_art || {}
    const newAreaArt = area_art || {}
    const newMovEntBase = {
      area_art,
      area,
      art,

      cantidad: 1
    }
    const endSearchBase = {
      searching: artName,
      results: []
    }

    if (movEnt && !curAreaArt.id && newAreaArt.id) {
      // To see if there are others like that
      const other = movEnts.find(me =>
        me.area_art && me.area_art.id == newAreaArt.id
      )

      let newMovEnts
      if (other) {
        const otherIdx = movEnts.indexOf(other)
        const newOther = {
          ...other,
          cantidad: other.cantidad + 1
        }

        // Replace with new cantidad
        const addedMovEnts = arrayReplace(movEnts, otherIdx, newOther)

        newMovEnts = inverseSlice(addedMovEnts, movEntIdx)
      } else {
        const newMovEnt = {
          ...movEnt,
          ...newMovEntBase,
          ...endSearchBase
        }

        newMovEnts = arrayReplace(movEnts, movEntIdx, newMovEnt)
      }

      // Check blank
      return withBlank({
        ...state,
        movEnts: newMovEnts
      })
    } else {
      // Article changed?
      if (curAreaArt.id != newAreaArt.id) {
        const newMovEnt = {
          ...newMovEntBase,
          ...endSearchBase
        }

        return withBlank({
          ...state,
          movEnts: arrayReplace(movEnts, movEntIdx, newMovEnt)
        })
      } else {
        return {
          ...state,
          ...endSearchBase
        }
      }
    }
  } else {
    const newMovEnt = {
      ...movEnt,
      
      searching: movEnt.art && movEnt.art.name || '',
      results: []
    }

    return {
      ...state,
      movEnts: arrayReplace(movEnts, movEntIdx, newMovEnt)
    }
  }
}

function movEntsReducer(state, action) {
  switch (action.type) {
  case 'update':
    return updateMovEnt(state, action.key, action.doc)
  case 'remove':
    return removeMovEnt(state, action.key)
  case 'set_results':
    return movEntSetResults(state, action.key, action.results)
  case 'select':
    return movEntSelectResult(state, action.key, action.idx, action.result)
  default:
    return state
  }
}

export function useMovEntsReducer(initialMovEnts = []) {
  const initialState = {
    lastKey: initialMovEnts.length,
    movEnts: initialMovEnts.map((me, idx) => {
      return {
        ...me,
        key: idx,
        searching: me.art && me.art.name
      }
    })
  }
  const initialPlusBlank = withBlank(initialState)

  const [ state, dispatch ] = useReducer(movEntsReducer, initialPlusBlank)
  const movEntsNoDeleted = state.movEnts.filter(me => !me._destroy)

  return {
    allMovEnts: state.movEnts,
    movEnts: movEntsNoDeleted,

    updateMovEnt(key, doc) {
      dispatch({
        type: 'update',
        key,
        doc
      })
    },

    removeMovEnt(key) {
      dispatch({
        type: 'remove',
        key
      })
    },

    movEntSetResults(key, results) {
      dispatch({
        type: 'set_results',
        key,
        results
      })
    },

    movEntSelect(key, idx = -1, result) {
      dispatch({
        type: 'select',
        key,
        idx,
        result
      })
    }
  }
}
