import classnames from 'classnames'

export const Wrapper = ({
  children,
  optional,
  wrapperClassName,
  label
}) => {
  const classes = classnames(
    'control-group',
    { optional },
    wrapperClassName
  )

  const labelClasses = classnames(
    'control-label',
    { optional }
  )

  return (
    <div className={classes}>
      <label className={labelClasses}>
        {label}
      </label>
      <div className="controls">
        {children}
      </div>
    </div>
  )
}
