export const Marca = {
  indexPath: '/marcas',

  fields: {
    name: {
      type: 'name',
      // pathInDocument: 'art.name'
    }
  },

  namePath: 'name'
}
