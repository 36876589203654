export const Radios = ({
  options,
  onChange,
  value,
  name
}) => {
  const handleChange = newOption => {
    if (onChange)
      onChange(newOption)
  }

  return (
    <label className="radio">
      {
        options.map(op =>
          <div
            key={op.value}
            onClick={() => !op.disabled && handleChange(op.value)}
          >
            <input
              type="radio"
              checked={value == op.value}
              onChange={() => {}}
              disabled={op.disabled}
            />
            {op.label}
          </div>
        )
      }
    </label>
  )
}
