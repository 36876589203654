export const Area = {
  indexPath: '/areas',

  fields: {
    name: {
      type: 'name'
    },
    desempaqueta: {
      type: 'boolean'
    },
    tax: {
      type: 'tax',
      cascade: false
    },

  },

  modelName: 'area',
  namePath: 'name'
}

