import Flex from '@react-css/flex'

import { TabbedPrice } from '../ui/TabbedPrice'

import styles from './MovEntCantidadDisplay.module.sass'

export const MovEntCantidadDisplay = ({ movEnt }) => {
  return (
    <Flex row noWrap>
      <TabbedPrice amount={movEnt.area_art.procesado} noBorder />
      <div className={styles.mult}>
        {`×${movEnt.cantidad}`}
      </div>
    </Flex>
  )
}
