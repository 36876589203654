export const Client = {
  indexPath: '/clientes',

  fields: {
    name: {
      type: 'name',
    },
    phone: {
      pathInDocument: 'telefono',
      type: 'string'
    },
    email: {
      type: 'email'
    },
    patients: {
      pathInDocument: 'pacientes',
      arrayHighlight: doc => doc.name,
      type: 'patients'
    }
  },

  modelName: 'cliente',
  namePath: 'name'
}
