import { Popover as TinyPopover } from 'react-tiny-popover'
import classnames from 'classnames'

import { CrossCloseButton } from '../bootstrap'
import { useEscape } from '../hooks/useEscape'

import styles from './Popover.module.sass'

// children is the element to open it (a button)
export const Popover = ({
  visible,
  header,
  content,
  children,
  onWantToClose,
  wide,
  noReposition,
  forceWidth,
  forceHeight,
  ...rest
}) => {
  useEscape(onWantToClose)

  header = typeof header == 'string' ?
    <strong>{header}</strong> : header

  const popoverContent = (data) => {
    const popoverClasses = classnames(
      'popover',
      'show',
      'left',  // FIXME data.position
      styles.popover,
      { [styles.wide]: wide }
    )
    return (
      <div className={popoverClasses}>
        <div className="arrow"></div>
        {
          header &&
            <h4 className="popover-title">
              {header}
              <CrossCloseButton onClick={onWantToClose} />
            </h4>
        }
        <div className="popover-content">
          {content}
        </div>
      </div>
    )
  }

  const places = [
    { position: 'top',     w: 0.5,  h: 0 },
    { position: 'right',   w: 1,    h: 0.5 },
    { position: 'bottom',  w: 0.5,  h: 1 },
    { position: 'left',    w: 0,    h: 0.5 }
  ]

  let toLoco
  if (noReposition) {
    toLoco = null
  } else {
    toLoco = ({ childRect, popoverRect }) => {
      const place = places[3]
      const width = forceWidth || popoverRect.width
      const height = forceHeight || popoverRect.height

      // left
      const left = childRect.x + childRect.width * place.w
        + width * (place.w - 1) + window.scrollX
      const top = childRect.y + childRect.height * place.h
        + height * (place.h - 1) + window.scrollY

      return {
        left: left > 0 ? left : 0,
        top: top > 0 ? top : 0
      }
    }
  }

  return (
    <TinyPopover
      isOpen={visible}
      content={popoverContent}
      contentLocation={toLoco}
      {...rest}
    >
      {children}
    </TinyPopover>
  )
}
