import { useState } from 'react'
import { Button, CrossCloseButton, Popover } from '../bootstrap'
import { pathForModel } from './crudUtils'
import { deleteFromVetus } from '../utils/fetch_with_alert'
import { tr } from '../translator'

export const DeleteButton = ({ model, doc, fetchOptions = {}, ...rest }) => {
  const [ open, setOpen ] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleDelete = async () => {
    try {
      handleClose()

      const deletePath = pathForModel({ model, doc, action: 'delete' })
      await deleteFromVetus(deletePath, [], fetchOptions)
    } catch(err) {
      console.error('Error while deleting', doc, err)
    }
  }

  const popoverContent = (
    <div className="alert alert-block alert-error fade in">
      <CrossCloseButton onClick={handleClose} />
      <h4>{tr('vetus.app.delete_warn_title')}</h4>
      <p>{tr('vetus.app.delete_warn')}</p>
      <p>
        <Button
          key="yes"
          className="btn-danger"
          icon="trash"
          onClick={handleDelete}
        >
          {tr('vetus.app.delete_yes')}
        </Button>
        <Button
          key="no"
          onClick={handleClose}
        >
          {tr('vetus.app.delete_no')}
        </Button>
      </p>
    </div>
  )

  return (
    <Popover
      isOpen={open}
      positions={['left', 'bottom']}
      padding={10}
      content={popoverContent}
      onWantToClose={handleClose}
    >
      <Button
        {...rest}
        key="delete"
        className="btn-danger"
        icon="trash"
        mini
        onClick={handleOpen}
      >
        {tr('helpers.links.delete')}
      </Button>
    </Popover>
  )
}
