import classnames from 'classnames'

import styles from './DataShow.module.sass'

export const DataShowContainer = ({ compact, children }) => {
  const classes = classnames('dl-horizontal', {
    [styles.compact]: compact
  })

  return (
    <dl className={classes}>
      {children}
    </dl>
  )
}

export const DataShow = ({ label, data }) => {
  if (data) {
    return (
      <div className={styles.line}>
        <dt>{label}</dt>
        <dd>{data}</dd>
      </div>
    )
  } else
    return null
}
