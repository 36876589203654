import { ViewWrap } from '../application/ViewWrap'
import { getModel } from '../models'
import { CrudContainer } from './CrudContainer'
import { CrudList } from './CrudList'
import { setEnv } from '../utils/environment'

export default ({
  collection,
  modelName,
  options,
  env,
  relModelName,
  relId,
  ...rest
}) => {
  setEnv(env)

  console.log("COLLECTION", collection)
  console.log("NODE NAME", modelName)
  console.log("OPTIONS", options)
  const model = getModel(modelName)

  return (
    <ViewWrap>
      <CrudContainer
        {...rest}
        model={model}
        collection={collection}
        resultsComponent={CrudList}
        relModelName={relModelName}
        relId={relId}
      />
    </ViewWrap>
  )
}
