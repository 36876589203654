export const DisplayBoolean = ({
  doc,
  value = null
}) => {
  if (typeof value != 'undefined') {
    const text = value ? '✓' : '✗'
    const className = value ? 'true' : 'false'

    return (
      <div className={className}>
        {text}
      </div>
    )
  } else
    return null
}

