
/**
 * If attr is not found in object, we create it in the form
 * object[attr] = defaultObject
 *
 * @param {object} object Object
 * @param {string} attr Key
 * @param {function} create Function that returns a brand new
 *    object to assign if it didn't exist. Important: It must be
 *    a new object.
 *
 * @returns New or existing object[attr]
 *
 * @example
 *  const obj = { existing: { count: 42 } }
 *
 *  valueOrCreate(obj, 'existing', () => ({ count: 0 }))
 *    // -> { count: 42 }
 *  valueOrCreate(obj, 'invented', () => ({ count: 0 }))
 *    // -> { count: 0 }
 */
export function valueOrCreate(object, attr, create)
{
  if (!(attr in object))
    object[attr] = create()

  return object[attr]
}

/**
 * Returns a new array without element at at.
 */
export function inverseSlice(arr, at)
{
  return [ ...arr.slice(0, at), ...arr.slice(at + 1) ]
}

/**
 * Creates new array replacing element at at.
 */
export function arrayReplace(arr, at, element)
{
  return [ ...arr.slice(0, at), element, ...arr.slice(at + 1) ]
}

export function arrayLast(arr)
{
  return arr[arr.length - 1]
}

export function positiveMod(x, mod)
{
	return ((x % mod) + mod) % mod
}

export function stringify(thing) {
  switch (typeof thing) {
  case 'string':
    return thing
  case 'number':
    return thing.toString()
  case 'boolean':
    return thing ? '1' : 'false'   // Rails thing
  default:
    return ''
  }
}

// Executes the function f only when time ms have
// passed after the last call.
//
// @return {object} { send, updateFunction }
//
// In React it's used like this:
// 
// const debounced = useRef(debounce(f, time))
// debounced.current.send(... bouncy stuff ...)
//
export function debounce(f, time = 100)
{
  let timer = false

  return {
    // Sends arguments to the function
    send() {
      const args = arguments

      if (timer)
        clearTimeout(timer)
      timer = setTimeout(function() {
        f.apply(null, args)
      }, time)
    },

    // Updates the function but not the timers
    updateFunction(newF) {
      f = newF
    }
  }
}

// Thanks Mariuzzo
// https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return (typeof item == 'object') && item !== null
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function deepmerge(target, ...sources) {
  if (!sources.length)
    return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          if (Array.isArray(source[key]))
            Object.assign(target, { [key]: [] })
          else
            Object.assign(target, { [key]: {} })
        }
        deepmerge(target[key], source[key])
      } else {
        Object.assign(target, { [key]: source[key] })
      }
    }
  }

  return deepmerge(target, ...sources)
}

/**
 * Makes sure it's an array. If not arrayizes it. If object
 * was falsy, returns empty array.
 */
export const ensureArray = obj => {
  if (obj)
    return Array.isArray(obj) ? obj : [obj]
  else
    return []
}

/**
 * Array.map for objects
 *
 * cb(value, key, index)
 */
export const objectMap = (object, cb) => {
  const keys = Object.keys(object)
  return keys.map((key, idx) => cb(object[key], key, idx))
}

/**
 * Array.reduce for object
 *
 * cb(acc, value, key, index)
 */
export const objectReduce = (obj, cb, initial) => {
  const keys = Object.keys(obj)
  return keys.reduce((acc, cur, idx) =>
    cb(acc, obj[cur], cur, idx)
  , initial)
}

/**
 * Attribute in object and returns
 * reference
 */
export const createIfMissing = (obj, attr, def) => {
  if (!(attr in obj))
    obj[attr] = def

  return obj[attr]
}

/**
 * Converts lf to <br>
 */
export const lfToHTMLBr = text => {
  if (text)
    return text.replace(/\r?\n/g, '<br>')
  else
    return ''
}
