/**
 * Transforms typical form path to array. Example:
 *
 * `pathToArray("pets[4].name") --> ['pets', '4', 'name']`
 * `pathToArray("pets.4.name") --> ['pets', '4', 'name']`
 */
export function pathToArray(path) {
  const unsquared = path.replace(/[[.](.*?)[\].]/g, '.$1.')
  return unsquared.split('.')
}

/**
 * Traverses an object using an array of keys.
 *
 * @param {object} object Object to traverse
 * @param {string|array} path Path in the form `"pets.4.name"`,
 *  `"pets[4].name"` or `['pets', '4', 'name']`
 * @param {object} options Optional options:
 *  {
 *    createIfMissing: false, // Creates missing entities with objects,
 *    returnValue: false,     // Ultimate value if you are not interested
 *                            // in context
 *  }
 *
 * @returns {array} Array in the form `[ object, attribute ]`
 *  (or empty if subobject is not found).
 *
 *  This allows you to mutate original object like this:
 *
 *  const [ container, attribute ] = objectTraverse(obj, path)
 *  container[attribute] = newValue
 *
 * TODO When createIfMissing, use path brackets as a
 * hint to when to create arrays or objects
 */
export function objectTraverse(object, pathOrArray, options = {}) {
  if (object) {
    const {
      createIfMissing,
      returnValue
    } = options

    const arrayed = Array.isArray(pathOrArray) ?
      pathOrArray : pathToArray(pathOrArray)
    const [ next, ...rest ] = arrayed

    if (next === '') {
      return object
    } else if (next in object) {
      if (rest.length == 0) {
        if (returnValue)
          return object[next]
        else
          return [ object, next ]
      } else {
        if (createIfMissing && typeof object[next] == 'undefined')
          object[next] = {}

        return objectTraverse(object[next], rest, options)
      }
    } else {
      if (createIfMissing) {
        object[next] = {}

        // Repeat
        return objectTraverse(object, arrayed, options)
      } else {
        if (returnValue)
          return null
        else
          return []
      }
    }
  } else
    return null
}

export function objectExtract(object, pathOrArray, options = {}) {
  return objectTraverse(object, pathOrArray, {
    ...options,
    returnValue: true
  })
}
