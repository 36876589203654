import { forwardRef } from 'react'
import classnames from 'classnames'

import { Button } from '../bootstrap'
import { tr } from '../translator'

export const StaticModal = ({
  className,
  closable,
  header,
  children,
  buttons,
  onSave,
  onClose,
  onlyClose,
  noClose,
  ...rest
}) => {
  const classes = classnames('modal', className)

  if (buttons === null) {
    buttons = []

    if (!onlyClose) {
      buttons.push(
        <Button
          key="save"
          text={tr('helpers.links.submitGeneric')}
          icon="ok"
          className="btn-primary"
          onClick={onSave}
        />
      )
    }

    if (!noClose) {
      buttons.push(
        <Button
          key="close"
          text={tr('helpers.links.close')}
          icon="remove"
          onClick={onClose}
        />
      )
    }
  }

  return (
    <div
      className={classes}
      {...rest}
    >
      <div className="modal-header">
        {
          closable &&
            <CrossCloseButton onClick={onClose} />
        }
        <h3>{header}</h3>
      </div>
      <div className="modal-body">
        {children}
      </div>
      <div className="modal-footer">
        {buttons}
      </div>
    </div>
  )
}
