import React, { useReducer } from 'react'
import { useCrudFetch } from '../hooks/useCrudFetch'
import { useCrudReducer } from '../hooks/useCrudReducer'
import { CrudView } from './CrudView'

const DEFAULT_PER_PAGE = 30

// ...rest will be passed to useCrudFetch
export const CrudContainer = ({
  name,
  model,
  collection: initialCollection,
  count: initialCount,
  resultsComponent,
  editComponent,
  searchFormComponent,
  searchFormProps,
  resultsProps,
  children,
  perPage = DEFAULT_PER_PAGE,
  relModelName,
  relId,
  ...rest
}) => {
  const [ state, dispatch ] = useCrudReducer(model)
  const {
    collection,
    count
  } = useCrudFetch({
    state,
    dispatch,
    perPage,
    model,
    initialCollection,
    initialCount,
    relModelName,
    relId,
    ...rest
  })

  console.log("CRUD STATE", state)
  console.log("COLLECTION", count, collection)

  const handleSearchInput = (value) => {
    dispatch({
      type: 'searchInput',
      searchInput: value
    })
  }

  const handleSearch = async (value) => {
    dispatch({
      type: 'search',
      search: value
    })
  }

  function handlePageChange(page) {
    dispatch({
      type: 'setPage',
      page: page + 1
    })
  }

  function handleDocChange(doc) {
    change({
      variables: {
        input: doc
      }
    })
  }

  return (
    <CrudView
      {...rest}
      name={name}
      model={model}
      collection={collection}
      count={count}
      state={state}
      dispatch={dispatch}
      searchText={state.searchInput}
      searching={state.search}
      onSearchChange={handleSearchInput}
      onSearch={handleSearch}
      page={state.page}
      onPageChange={handlePageChange}
      perPage={perPage}
      resultsComponent={resultsComponent}
      searchFormComponent={searchFormComponent}
      editComponent={editComponent}
      onDocChange={handleDocChange}
      children={children}
      searchFormProps={searchFormProps}
      resultsProps={resultsProps}
    />
  )
}
