import { FormHorizontal, Input } from '../bootstrap'
import { TaxSelect } from '../taxes/TaxSelect'
import { tr } from '../translator'
import { AreaArtShowContext } from './AreaArtShowContext'
import { PriceTaxedEditContainer } from './PriceTaxedEditContainer'
import { isProviderFromName } from '../utils/special_areas'
import { getTax } from '../utils/movimientos'

export const AreaArtEdit = ({
  areaArt = {},
  area = {},
  art = {},
  taxes,
  forceTax,
  isForcedTax,
  onTaxChange,
  onPriceChange,
  onPriceBoxChange
}) => {
  const { tax } = getTax(areaArt, area, forceTax)

  const impuestoSelect = () =>
    <TaxSelect
      taxId={tax.id}
      taxes={taxes}
      value={tax.id}
      onChange={onTaxChange}
      classNamePrefix="taxchanger"
      isDisabled={isForcedTax}
    />

  const isProvider = isProviderFromName(area.name)
  const isBoxable = !isProvider && art.subdiv && art.subdiv > 1
  const groupSingleLabel = tr('simple_form.labels.precio.precio')
  const groupBoxLabel = tr('simple_form.labels.precio_box.precio')

  return (
    <>
      <AreaArtShowContext
        areaArt={areaArt}
        area={area}
        art={art}
        forceTax={forceTax}
      />
      <FormHorizontal condensed component="div">
        <div data-key="tax-change">
          <Input
            key="tax"
            label={tr('activerecord.models.impuesto.one')}
            inputComponent={impuestoSelect}
          />
        </div>
        <PriceTaxedEditContainer
          key="price"
          taxName={tax.name}
          taxFactor={tax.factor}
          areaName={area.name}
          onSubmit={onPriceChange}
          subdiv={art.subdiv}
          groupLabel={groupSingleLabel}
          partOfMore={isBoxable}
          canSubmit
        />
        {
          isBoxable &&
            <PriceTaxedEditContainer
              key="price-box"
              taxName={tax.name}
              taxFactor={tax.factor}
              areaName={area.name}
              onSubmit={onPriceBoxChange}
              subdiv={art.subdiv}
              groupLabel={groupBoxLabel}
              partOfMore
              canSubmit
              forBox
            />
        }
      </FormHorizontal>
    </>
  )
}
