import classnames from 'classnames'
import { Icon } from '../../bootstrap'

export const DisplayLabel = ({
  model,
  fieldSchema,
  fieldName,
  value
}) => {
  const {
    labels,
    labelStyle,
    labelStyles,
    labelIcons,
    labelText
  } = fieldSchema

  const label = labels[value]
  if (label) {
    const type = label.key

    const classes = classnames(labelStyle, labelStyles[type])
    const icon = labelIcons[value]

    const text = labelText(type)

    return (
      <div className={classes}>
        <Icon
          icon={icon}
          text={text}
        />
      </div>
    )
  } else
    return null
}
