import { useState } from 'react'

import { Button } from '../../bootstrap'
import { tr } from '../../translator'
import { lfToHTMLBr } from '../../utils/utils'

export const DisplayPrivateText = ({ value }) => {
  const [ visible, setVisible ] = useState(false)

  const buttonStringPart = visible ? 'hide' : 'show'
  const buttonClass = value ? 'btn-warning' : ''

  const handleToggleVisible = () => {
    setVisible(!visible)
  }

  return (
    <>
      <Button
        icon={visible ? 'eye-close' : 'eye-open'}
        text={tr(`helpers.links.${buttonStringPart}`)}
        className={buttonClass}
        onClick={handleToggleVisible}
        small
      />
      {
        visible &&
          <div dangerouslySetInnerHTML={{ __html: lfToHTMLBr(value) }} />
      }
    </>
  )
}
