import { useRef, useEffect } from 'react'
import classnames from 'classnames'

import { CrossCloseButton, Button } from '../bootstrap'
import { tr } from '../translator'

export const Modal = ({
  children,
  visible,
  onSave,
  onClose,
  ...rest
}) => {
  const modalRef = useRef()

  console.log("MODAL (RE)RENDER VISIBLE", visible)
  console.error("Modals area not working right now")

  useEffect(() => {
    try {
      console.log("SET VISIBLE", visible)
      if (modalRef.current)
        $(modalRef.current).modal({ show: visible })
    } catch (err) {
      console.error(err)
    }
  }, [ visible ])

  const handleSave = () => {
    if (onSave)
      onSave()
    handleClose()
  }

  const handleClose = () => {
    if (onClose)
      onClose()
  }

  return (
    <StaticModal
      {...rest}
      className="creation-modal show fade"
      ref={modalRef}
      role="dialog"
      data-show={visible}
      onSave={handleSave}
      onClose={handleClose}
    >
      {children}
    </StaticModal>
  )
}
