import { useDebouncer } from '../hooks/useDebounce'
import { Input, Row, Icon } from '../bootstrap'

export const Search = ({
  name,
  search,
  onChange,
  onSearch
}) => {
  const { send, updateCallback } = useDebouncer()
  updateCallback(onSearch)

  const handleChange = (newValue) => {
    onChange(newValue)
    send(newValue)
  }

  return (
    <Row>
      <div className="span5">
        <Input
          className="search-query span4"
          name={name}
          onChange={handleChange}
          value={search}
          autoFocus
          inline
        />
        &nbsp;
        <Icon icon="search" />
      </div>
    </Row>
  )
}
