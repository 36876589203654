import { useState } from 'react'
import { TaxSelect } from '../taxes/TaxSelect'
import { Button } from '../bootstrap'
import { getTax } from '../utils/movimientos'

import styles from './ChangeableTax.module.sass'

export const ChangeableTax = ({
  idx,
  areaArt,
  area,
  taxes,
  forceTax,
  onChange,
  keyForData
}) => {
  const [ editing, setEditing ] = useState(!!forceTax)

  const { tax } = getTax(areaArt, area, forceTax)

  const handleClickEdit = () => {
    setEditing(true)
  }

  const handleClickReset = () => {
    setEditing(false)
    onChange(null)
  }

  if (editing) {
    return (
      <div className={styles.container} data-key={keyForData}>
        <TaxSelect
          taxId={tax.id}
          taxes={taxes}
          value={tax.id}
          onChange={onChange}
          mini
        />
        <Button
          className={styles.changer}
          onClick={handleClickReset}
          icon="remove-sign"
          dataKey={`${keyForData}-reset`}
        />
      </div>
    )
  } else {
    return (
      <div className={styles.container} data-key={keyForData}>
        <div className={styles.chicha}>
          {tax.name}
        </div>
        <Button
          className={styles.changer}
          onClick={handleClickEdit}
          icon="wrench"
          dataKey={`${keyForData}-open`}
        />
      </div>
    )
  }
}
