import classnames from 'classnames'

import styles from './Table.module.sass'

export const Table = ({
  children,
  className,
  stripped,
  bordered,
  condensed,
  hover,
  ...rest
}) => {
  const tableClasses = classnames(
    'table',
    'some-margin-top',
    styles.table,
    className,
    {
      'table-striped': stripped,
      'table-bordered': bordered,
      'table-condensed margin-top': condensed,
      'table-hover': hover
    }
  )

  return (
    <table className={tableClasses}>
      {children}
    </table>
  )
}

export const CenteredTD = ({ className, children, ...rest }) => {
  const classes = classnames(className, styles.centered)

  return (
    <td {...rest} className={classes}>
      {children}
    </td>
  )
}
