import styles from '../crud/display/DisplayNumber.module.sass'

export const Art = {
  indexPath: '/arts',

  fields: {
    name: {
      type: 'name'
    },
    pictures: {
      type: 'pictures'
    },
    store_price: {
      pathInDocument: 'store_area_art',
      type: 'price',
      addProps: (value, doc) => {
        if (doc.not_soldable)
          return { unsoldable: true }
        else
          return {}
      }
    },
    store_amount: {
      pathInDocument: 'store_area_art.cantidad',
      type: 'number',
      displayClass: ({ doc, value }) => {
        if (value < 0)
          return styles.veryBad
        else if (value < doc.stock_alert)
          return styles.bad
      },
    },
    marca: {
      type: 'associated',
      otherClass: 'marcas',
    },
    principios: {
      type: 'ingredients'
    }
  },

  modelName: 'art',
  namePath: 'name'
}
