import Flex from '@react-css/flex'
import { ShowPicture } from '../../ui/ShowPicture'
import { PatientThumbnail } from '../../clients/PatientThumbnail'
import { navigateTo } from '../../utils/http'
import classnames from 'classnames'
import styles from './Patient.module.sass'

export const Patient = ({
  idx,
  client,
  patient,
  contents,
  ...rest
}) => {
  const targetUrl = `/clientes/${client.id}/pacientes/${patient.id}/edit`
  const handlePatientClick = (e) => {
    e.preventDefault()
    navigateTo(targetUrl)
  }

  const { pictures } = patient
  const patientStyles = classnames(styles.name, {
    [styles.deaded]: patient.deaded
  })

  return (
    <a
      key={patient.id}
      className={styles.container}
      onClick={handlePatientClick}
      href={targetUrl}
    >
      <PatientThumbnail
        {...rest}
        patient={patient}
        mini
      />
      <div className={patientStyles}>
        {contents}
      </div>
    </a>
  )
}
