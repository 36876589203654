import classnames from 'classnames'

import {
  priceProcess,
  roundForShow,
  SHOW_GORY_DETAILS
} from '../utils/money'

import styles from './TabbedPrice.module.sass'

export const TabbedPrice = ({ amount, bold, noBorder }) => {
  const {
    integer,
    decimalsS,
    moreDecimalsS
  } = roundForShow(amount)

  const wrapClasses = classnames(styles.priceContainer, {
    [styles.border]: !noBorder,
    [styles.bold]: bold
  })

  return (
    <div className={wrapClasses}>
      <div className={styles.integer}>
        {integer},
      </div>
      <div className={styles.decimals}>
        {decimalsS}
        {
          SHOW_GORY_DETAILS &&
            <span className={styles.moreDecimals}>
              {moreDecimalsS}
            </span>
        }
        {' €'}
      </div>
    </div>
  )
}
