import { ShowPicture } from '../ui/ShowPicture'
import { mimetypeIsImage } from '../utils/images'

const PREFABS = '/assets/patient_types'

const translateSpices = {
  0: 'unset',
  1: 'dog',
  2: 'cat',
  3: 'other'
}

const translateSexes = {
  0: 'unset',
  1: 'male',
  2: 'female'
}

const genericImages = {
  cat: {
    male: 'cat_male',
    female: 'cat_female',
    unset: 'cat_nosex'
  },
  dog: {
    male: 'dog_male',
    female: 'dog_female',
    unset: 'dog_nosex'
  },
  unk: 'monster'
}

export const PatientThumbnail = ({
  patient,
  className,
  mini,
  patientHelpers
}) => {
  const files = patient.pictures || []
  let image = files.reduce((found, cur = {}) => {
    return found || (mimetypeIsImage(cur.content_type) && cur)
  }, null)

  if (!image) {
    let base

    const spices = translateSpices[patient.especie]
    const sex = translateSexes[patient.sexo]
    const spiced = genericImages[spices] 
    if (spiced && sex in spiced)
      base = spiced[sex]
    else
      base = genericImages.unk

    image = {
      full_t: patientHelpers[base],
      is_picture: true
    }
  }

  return (
    <ShowPicture
      className={className}
      pic={image}
      mini={mini}
      noLink
      noBootstrap
    />
  )
}

