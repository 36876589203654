import { useState } from 'react'
import Select from 'react-select'

import { getSelected } from '../utils/options_parse'

// onChange will return the full tax object or undefined
export const TaxSelect = ({
  taxId,
  taxes,
  onChange,
  mini,
  dataKey,
  ...rest
}) => {
  const taxOptions = taxes.map(tax => ({
    label: tax.name,
    value: tax.id
  }))
  const initialDest = getSelected(taxOptions, taxId)
  const [ taxOption, setTaxOption ] = useState(initialDest)

  const handleSelect = (selection) => {
    const tax = taxes.find(tax => tax.id == selection.value)

    setTaxOption(selection)
    if (onChange)
      onChange(tax)
  }

  return (
    <Select
      {...rest}
      options={taxOptions}
      value={taxOption}
      onChange={handleSelect}
      mini={mini}
      hasValue
    />
  )
}
