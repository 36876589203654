import { MovEntTableShow } from './MovEntTableShow'
import { Table, Well } from '../bootstrap'
import { tr } from '../translator'

import styles from './InTableMovEnts.module.sass'
import { priceRead, roundForShowPlain } from '../utils/money'

export const InTableMovEnts = ({
  doc,
  highlight
}) => {
  let label = ''
  const cash = priceRead(doc.contado) || 0
  const transfer = priceRead(doc.transferencia) || 0
  const card = priceRead(doc.total) - cash - transfer
  if (cash) {
    label += tr('vetus.movimientos.short_cash', {
      cash: roundForShowPlain(cash)
    })
  }
  if (card && card > 0.01) {
    if (label != '')
      label += ', '
    label += tr('vetus.movimientos.short_card', {
      card: roundForShowPlain(card)
    })
  }
  if (transfer) {
    if (label != '')
      label += ', '
    label += tr('vetus.movimientos.short_transfer', {
      transfer: roundForShowPlain(transfer)
    })
  }

  return (
    <tr>
      <td colspan="100%">
        <Well label={label} className={styles.half}>
          <Table condensed>
            <tbody>
              {
                doc.mov_ents.map(me =>
                  <MovEntTableShow movEnt={me} highlight={highlight} />
                )
              }
            </tbody>
          </Table>
        </Well>
      </td>
    </tr>
  )
}
