import { useState } from 'react'
import { priceRead, inputPriceToString } from '../utils/money'
import { isProviderFromName } from '../utils/special_areas'
import { Well } from '../bootstrap'
import { tr } from '../translator'

import { PriceTaxedEdit } from './PriceTaxedEdit'

export const PriceTaxedEditContainer = ({
  initialRaw,
  initialProcesado,
  areaName,
  onChange,
  subdiv,
  canSubmit,
  groupLabel,
  ...rest
}) => {
  const initialRawS = inputPriceToString(initialRaw)
  const initialProcesadoS = inputPriceToString(initialProcesado)
  const [ raw, setRaw ] = useState(initialRawS)
  const [ processed, setProcessed ] = useState(initialProcesadoS)

  const isProvider = isProviderFromName(areaName)
  const isBoxable = !isProvider && subdiv && subdiv > 1

  const rawNumber = priceRead(raw, 0)
  const processedNumber = priceRead(processed, 0)

  const unitLabel = tr('activerecord.models.precio.one')
  const boxLabel = isBoxable ? `× ${subdiv} =` : ''

  const handleChange = ({ raw, procesado }) => {
    if (onChange)
      onChange({ raw, procesado })

    setRaw(raw)
    setProcessed(procesado)
  }

  const handleBoxChange = ({ raw, procesado }) => {
    const rawNumber = priceRead(raw, 0)
    const processedNumber = priceRead(procesado, 0)

    handleChange({
      raw: rawNumber / subdiv,
      procesado: processedNumber / subdiv
    })
  }

  const contents = (
    <>
      <PriceTaxedEdit
        key="unit"
        {...rest}
        raw={raw}
        procesado={processed}
        subdiv={subdiv}
        isProvider={isProvider}
        areaName={areaName}
        onChange={handleChange}
        canSubmit={canSubmit}
        label={unitLabel}
        partOfMore={isBoxable}
      />
      {
        isBoxable &&
          <PriceTaxedEdit
            key="box"
            {...rest}
            raw={rawNumber * subdiv}
            procesado={processedNumber * subdiv}
            subdiv={subdiv}
            isProvider={isProvider}
            areaName={areaName}
            onChange={handleBoxChange}
            canSubmit={false}
            label={boxLabel}
            partOfMore
          />
      }
    </>
  )

  if (isBoxable) {
    return (
      <Well label={groupLabel}>
        {contents}
      </Well>
    )
  } else {
    return contents
  }
}
