import { relativeToParam } from '../utils/fetch'
import { ExportButton } from './ExportButton'

export const GenericExport = ({
  relModelName,
  relId,
  model
}) => {
  const relParam = relativeToParam(relModelName, relId)
  const searchAppend = relParam ? `?${relParam}` : ''

  return (
    <ExportButton
      href={`/${model.name}.csv${searchAppend}`}
    />
  )
}
