import { useState, useEffect } from 'react'
import { modalPubSub } from '../utils/modal_pubsub'

import { Modal } from './Modal'

export const Modals = () => {
  const [ modals, setModals ] = useState([])

  const handleOpen = newModal => {
    const idx = modals.length

    setModals([ ...modals, newModal ])
    return idx
  }

  const handleClose = id => {
    const newModals = arrayReplace(modals, id, null)
    setModals(newModals)
  }

  useEffect(() => {
    modalPubSub.subscribe('open', handleOpen)
    modalPubSub.subscribe('close', handleClose)

    return () => {
      modalPubSub.unsubscribe('open', handleOpen)
      modalPubSub.unsubscribe('close', handleClose)
    }
  })

  console.log("GATEWAY MODALS", modals)

  return (
    <>
      {
        modals.reduce((modals, modal, idx) => {
          if (modal) {
            const id = `reactive-modal-${idx}`

            modals.push(
              <Modal key={id} id={id} visible>
                {modal.render}
              </Modal>
            )
          }

          return modals
        }, [])
      }
    </>
  )
}
