import DatePicker from 'react-datepicker'
import { isTest } from '../../utils/environment.js'
import { parseDate, dateFormat } from '../../utils/date'

import "react-datepicker/dist/react-datepicker.css"

export const DateRange = ({
  id,
  onChange,
  value = {}
}) => {
  const handleChange = (dates) => {
    const [ start, end ] = dates

    let cleaned = {}
    if (start)
      cleaned.start = start
    if (end)
      cleaned.end = end

    onChange(cleaned)
  }

  const handleTestChange = (e) => {
    const value = e.target.value
    const dates = value.split(' - ')
    if (dates.length == 2 && dates[0] && dates[1]) {
      const from = parseDate(dates[0])
      const to = parseDate(dates[1])
      if (!isNaN(from) && !isNaN(to)) {
        console.log("TEST CHANGE", from, to)
        handleChange([ from, to ])
      }
    }
  }

  return (
    <>
      <DatePicker
        id={id}
        dateFormat={dateFormat}
        selected={value.start}
        onChange={handleChange}
        startDate={value.start}
        endDate={value.end}
        value={value}
        selectsRange
      />
      {
        isTest() &&
          <input
            id="datepicker-mock-input"
            onChange={handleTestChange}
            onBlur={handleTestChange}
          />
      }
    </>
  )
}
