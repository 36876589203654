import { createSchema } from 'react-hook-form-auto'
import { MovimientoIndexFilters } from '../movimientos/MovimientoIndexFilters'
import { add } from 'date-fns'
import { dateToDisplay } from '../utils/date'
import { tr } from '../translator'
import { InTableMovEnts } from '../movimientos/InTableMovEnts'

export const movimientoFilterSchema = createSchema('movimientoFilters', {
  temporal: {
    type: 'boolean'
  }
})

export const initialFilterState = (defaultInitial = {}) => {
  return {
    ...defaultInitial,
    filterTemp: false
  }
}

export const Movimiento = {
  indexPath: '/movimientos',
  indexExtraRows: (doc, highlight) => {
    const highlightPaths = Object.keys(highlight)
    const has = highlightPaths.reduce((found, cur) => {
      return found || cur.indexOf('mov_ents') != -1
    }, false)

    if (has) {
      return (
        <InTableMovEnts
          doc={doc}
          highlight={highlight}
        />
      )
    } else {
      return null
    }
  },

  fields: {
    name_pretty: {
      type: 'id'
    },
    is_factura: {
      type: 'boolean'
    },
    obs: {
      type: 'collapseText'
    },
    created_at: {
      type: 'date'
    },
    cliente: {
      type: 'associated',
      otherClass: 'clientes',
      highlightPath: () => 'cliente.name'
    }
  },

  filter: {
    reducer: (defaultReducer) => {
      return function movimientoFilter(state, action) {
        switch (action.type) {
        case 'filter-temp':
          {
            return {
              ...state,
              filterTemp: action.temporal,
              firstFetch: false
            }
          }
        case 'filter-reset':
          return initialFilterState()
        default:
          return defaultReducer(state, action)
        }
      }
    },
    initial: initialFilterState,
    params: (state) => {
      return {
        temp: state.filterTemp,
        direction: 'desc',
        sort: 'updated_at'
      }
    },
    fetchOn: (state) => [
      state.filterTemp
    ],
    ui: MovimientoIndexFilters
  },

  listTrClasses: doc => doc.temp && 'error',

  modelName: 'movimiento',
  namePath: 'name_pretty'
}
