import classnames from 'classnames'

import { Icon } from './Icon'

export const Badge = ({
  className,
  icon,
  children
}) => {
  const classes = classnames(
    'badge',
    className && 'badge-' + className
  )

  return (
    <span className={classes}>
      <Icon className={icon} />
      {' '}{children}
    </span>
  )
}
