import Flex from '@react-css/flex'
import { useState } from 'react'

import { NumberInput, Button, Popover } from '../bootstrap'
import { DetailedDiscount } from './DetailedDiscount'
import { tr } from '../translator'

export const DiscountEdit = ({
  descuento,
  onChange,
  idx,
  tabIndex,
  ...rest
}) => {
  const [ detailed, setDetailed ] = useState(false)

  const handleOpen = () => {
    setDetailed(true)
  }

  const handleClose = () => {
    setDetailed(false)
  }

  const handleChange = descuento => {
    if (onChange)
      onChange(descuento)
  }

  const popoverContent = detailed && (
    <DetailedDiscount
      {...rest}
      descuento={descuento}
      onChange={handleChange}
    />
  )

  const dataKey = typeof idx == 'undefined' ?
    'main' : idx

  const popoverButton = (
    <Button
      icon="cog"
      onClick={handleOpen}
      data-key={`dis-op-${dataKey}`}
    />
  )

  return (
    <>
      <Popover
        isOpen={detailed}
        positions={['left', 'bottom', 'top', 'right']}
        padding={10}
        header={tr('vetus.fact.update_area_art')}
        content={popoverContent}
        onWantToClose={handleClose}
        wide
      >
        <NumberInput
          className="span1 input-descuento"
          value={descuento}
          onChange={handleChange}
          buttonAddOn={popoverButton}
          data-key={`dis-input-${dataKey}`}
          inline
          tabIndex={tabIndex}
        />
      </Popover>
    </>
  )
}
