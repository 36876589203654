import {
  priceRead,
  discountToFactor,
  priceToString,
  applyTax,
  applyDiscount,
  numberToCannonical
} from './money'
import { arrayReplace } from './utils'

import { postToVetus, objectToRequest } from './fetch'
import { tr } from '../translator'
import { isProviderFromName } from './special_areas'

export function calcMovEntTotal(movEnt, { isProvider })
{
  const {
    art,
    area,
    area_art,
    impuesto
  } = movEnt
  const amount = priceRead(movEnt.cantidad)
  if (amount) {
    const isBox = !isProvider && art.subdiv > 1 && (amount % art.subdiv == 0)
    const rawSrc = isBox ?
      (area_art.raw_box || area_art.raw) : area_art.raw
    const procesadoSrc = isBox ?
      (area_art.procesado_box || area_art.procesado) : area_art.procesado

    const raw = priceRead(rawSrc)
    const { tax } = getTax(area_art, area, impuesto)
    const procesado = applyTax(raw, tax)

    if (raw || procesado) {
      return {
        raw: raw * amount,
        procesado: procesado * amount,
        tax,
        isBox
      }
    }
  }
}

export function applyMovEntDiscount(movEnt, totals)
{
  const descuento = discountToFactor(movEnt.descuento)

  return {
    ...totals,
    raw: totals.raw * (1 - descuento),
    procesado: totals.procesado * (1 - descuento)
  }
}

// After much consideration (ten seconds) I'm going to
// repeat the calculations for every line instead of
// changing state
export function calcMovimientoTotal(movEnts, { isProvider })
{
  const initial = {
    raw: 0,
    procesado: 0,
    taxName: null
  }

  return movEnts.reduce((totals, movEnt) => {
    const meSum = calcMovEntTotal(movEnt, { isProvider })
    const meSumDiscount = meSum && applyMovEntDiscount(movEnt, meSum)

    if (meSumDiscount) {
      // It will become permanent because tax.name
      // will never be 'impuestos' (tr('vetus.app.taxes'))
      const { tax } = meSum
      const isMultipleTaxes = totals.taxName &&
        totals.taxName != tax.name

      return {
        raw: totals.raw + meSumDiscount.raw,
        procesado: totals.procesado + meSumDiscount.procesado,
        taxName: isMultipleTaxes ?
          tr('vetus.app.taxes').toLowerCase() : tax.name,
        isMultipleTaxes
      }
    } else
      return totals
  }, initial)
}

export function calcTotalDiscount(totals, discount)
{
  return {
    raw: applyDiscount(discount, totals.raw),
    procesado: applyDiscount(discount, totals.procesado),
  }
}

// This may be done by combining taxes like multiple
// discount does. I will just use the cheap calculation
// for now
export function calcTaxCombinedFactor(sum)
{
  // const { vanilla } = sum
  return sum.procesado / sum.raw
}

export function prettyMultipleTaxName(sum)
{
  if (sum.isMultipleTaxes) {
    const taxCombinedFactor = calcTaxCombinedFactor(sum)
    const percent = (taxCombinedFactor - 1) * 100
    const displayDot = percent.toFixed(2)
    const displayComma = displayDot.replace('.', ',')
    return `${displayComma}% ${sum.taxName}`
  } else {
    return sum.taxName
  }
}

export function discountLabel(discount)
{
  const roundableDiscount = priceToString(discount, true)
  const discountText = tr('vetus.movimientos.discount').toLowerCase()
  return roundableDiscount && `${roundableDiscount}% ${discountText}`
}

export function movimientoSubmit({
  isFactura,
  clientId,
  observations,
  areaDest,
  movEnts,
  discount,
  payments,
  sumDiscounted,
  movimientoId
}) {
  const { procesado } = sumDiscounted

  let dataRaw = {
    movimiento: {
      obs: observations,
      cliente_id: clientId,
      is_factura: isFactura,
      area_id: areaDest,
      descuento: numberToCannonical(discount),
      contado: !isFactura && (payments.contado || 0) * procesado,
      // We don't pass tarjeta because client performs a rest
      // tarjeta: !isFactura && (payments.card || 0) * procesado,
      transferencia: !isFactura && (payments.transfer || 0) * procesado,
      mov_ents_attributes: movEnts.reduce((objectize, me, idx) => {
        if (me.area_art) {
          const objectKey = me.id ? me.id : idx

          return {
            ...objectize,
            [objectKey]: {
              id: me.id,
              _destroy: me._destroy || false,
              area_art_id: me.area_art.id,
              descuento: numberToCannonical(me.descuento),
              cantidad: me.cantidad,
              impuesto_id: me.impuesto ? me.impuesto.id : null
            }
          }
        } else
          return objectize
      }, {})
    }
  }

  if (movimientoId)
    dataRaw._method = 'patch'

  const data = objectToRequest(dataRaw)

  const urlAppend = movimientoId ? `/${movimientoId}` : ''
  return postToVetus(`/movimientos${urlAppend}`, data, { redirect: true })
    // .then(result => {
    //   return result.status == 0 ? good : bad
    // })
} 

export function initialPaymentsFromMovimiento(movimiento, movimientoTotal) {
  if ('contado' in movimiento && 'transferencia' in movimiento) {
    const contado = priceRead(movimiento.contado)
    const transfer = priceRead(movimiento.transferencia)
    const total = priceRead(movimientoTotal)
    if (total > 0) {
      const contadoFactor = contado / total
      const transferFactor = transfer / total
      const cardFactor = 1 - contadoFactor - transferFactor

      return {
        contado: contadoFactor,
        transfer: transferFactor,
        card: cardFactor
      }
    }
  }

  return { contado: 1 }
}

// From json reply from vetus
export function checkAACreated(result) {
  try {
    if (result && result.ok) {
      const jsonedAA = result.body

      if (jsonedAA) {
        const { new_aa } = jsonedAA

        if (new_aa)
          return railsToReactAreaArt(new_aa)
      }
    }

    return {}
  } catch (err) {
    console.error(err)
  }
}

export function getTax(areaArt = {}, area = {}, forceTax) {
  if (forceTax) {
    return {
      tax: forceTax,
      isForcedTax: true
    }
  } else if (areaArt && areaArt.tax && areaArt.tax.id) {
    return {
      tax: areaArt.tax,
      isOwnTax: true
    }
  } else if (area && area.tax && area.tax.id) {
    return {
      tax: area.tax,
      isOwnTax: false
    }
  } else {
    return {
      tax: {
        name: '',
        factor: 1
      },
      taxIsOwn: false
    }
  }
}

export function railsToReactAreaArt(doc) {
  const { art, area, ...area_art } = doc
  const { tax, tax_no_cascade, ...restAA } = area_art

  return {
    art,
    area,
    area_art: {
      ...restAA,
      tax: tax_no_cascade
    }
  }
}
