import { toast } from 'react-toastify'
import { tr } from '../translator'
import {
  postToVetus as postToVetusWithoutAlert,
  deleteFromVetus as deleteFromVetusWithoutAlert
} from './fetch'

function createAlerter({
  alertStringId
}) {
  return (result = {}, options = {}) => {
    if (result.ok) {
      toast(tr(alertStringId), {
        type: 'success',
        hideProgressBar: true
      })
    }

    if (options.error) {
      toast(tr('flash.error_explain'), {
        title: tr('flash.error'),
        type: 'error',
        hideProgressBar: true
      })
    }

    if (options.fatal) {
      toast(result.error, {
        type: 'error',
        hideProgressBar: true
      })
    }
  }
}

function tryAction(action) {
  try {
    return action()
  } catch (err) {
    toast(err, {
      type: 'error',
      hideProgressBar: true
    })
  }
}

export function postToVetus(url, params, options = {}) {
  return tryAction(() =>
    postToVetusWithoutAlert(url, params, {
      ...options,

      alert: createAlerter({
        alertStringId: 'flash.success_update'
      })
    })
  )
}

export function deleteFromVetus(url, params = [], options = {}) {
  return tryAction(() =>
    deleteFromVetusWithoutAlert(url, params, {
      alert: createAlerter({
        alertStringId: 'flash.success_delete',
      })
    })
  )
}
