import { Marca } from './marca'
import { Art } from './art'
import { Area } from './area'
import { AreaArt } from './area_art'
import { Picture } from './picture'
import { Principio } from './principio'
import { Family } from './family'
import { Place } from './place'
import { Impuesto } from './impuesto'
import { Recount } from './recount'
import { Section } from './section'
import { Client } from './client'
import { Patient } from './patient'
import { Memo } from './memo'
import { Movimiento } from './movimiento'

const models = {
  marcas: Marca,
  arts: Art,
  areas: Area,
  area_arts: AreaArt,
  pictures: Picture,
  places: Place,
  principios: Principio,
  families: Family,
  impuestos: Impuesto,
  inventariados: Recount,
  sections: Section,
  clientes: Client,
  patients: Patient,
  memos: Memo,
  movimientos: Movimiento
}

export function getModel(name) {
  const model = models[name]

  if (model) {
    return {
      ...model,
      name
    }
  }
}
