import { useState } from 'react'
import classnames from 'classnames'

import { Table, NumberInput } from '../bootstrap'
import { tr } from '../translator'
import { priceRead } from '../utils/money'

import styles from './SetupPayments.module.sass'

export const SetupPayments = ({
  type,
  payments,
  total,
  onChange,
  paymentMethods
}) => {
  const defMethod = paymentMethods.reduce((selected, cur) => {
    return selected || (cur != type ? cur : selected)
  }, null)

  const [ other, setOther ] = useState(defMethod)

  const handleOther = (newOther) => {
    if (newOther != type)
      setOther(newOther)
  }

  const chooseOtherOptions = kind => {
    return {
      value: kind,
      label: tr(`vetus.movimientos.other_${kind}`),
      disabled: kind == type
    }
  }

  const handleNumberInput = (method, newValue) => {
    const newAmount = priceRead(newValue)
    if (typeof newAmount != 'undefined') {
      const blockedFactor = paymentMethods.reduce((found, method) => {
        if (method != type && method != other)
          return found + (payments[method] || 0)
        else
          return found
      }, 0)

      const factor = newAmount / total
      if (factor >= 0 && factor <= 1) {
        onChange({
          ...payments,
          [type]: factor,
          [other]: 1 - blockedFactor - factor
        })
      }
    }
  }

  return (
    <Table condensed bordered>
      <tbody>
        {
          paymentMethods.map(method => {
            const chooseOps = chooseOtherOptions(method)
            const labelClasses = classnames(styles.radioLabel, {
              [styles.disabled]: method == type
            })

            return (
              <tr key={method} className={styles.trCenter}>
                <td key="other">
                  <input
                    type="radio"
                    checked={other == method}
                    onClick={() => handleOther(method)}
                    onChange={() => { /* silence uncontrolled */ }}
                    disabled={chooseOps.disabled}
                  />
                  <span className={labelClasses} onClick={() => handleOther(method)}>
                    {chooseOps.label}
                  </span>
                </td>
                <td key="edit">
                  <NumberInput
                    key={method}
                    value={(payments[method] || 0) * total}
                    onChange={newValue => handleNumberInput(method, newValue)}
                    disabled={method != type}
                    inline
                  />
                </td>
              </tr>
            )
          })
        }
      </tbody>
    </Table>
  )
}
