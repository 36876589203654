import Flex from '@react-css/flex'
import { Button } from '../bootstrap'
import { tr } from '../translator'
import { DeleteButton } from './DeleteButton'
import { pathForModel } from './crudUtils'

export const DisplayButtons = ({
  model,
  doc
}) => {
  return (
    <Flex row noWrap className="no-break">
      {
        model.buttons && model.buttons.add &&
          model.buttons.add.map(($button) =>
            <$button key={doc.id} model={model} doc={doc} />
          )
      }
      {
        model.buttons && model.buttons.hasShow &&
          <Button
            key="show"
            icon="eye-open"
            className="btn-primary"
            href={pathForModel({ model, doc, action: 'show' })}
            dataKey={`show-${doc.id}`}
            mini
          >
            {tr('helpers.links.show')}
          </Button>
      }
      <Button
        key="edit"
        icon="edit"
        href={pathForModel({ model, doc, action: 'edit' })}
        dataKey={`edit-${doc.id}`}
        mini
      >
        {tr('helpers.links.edit')}
      </Button>
      <DeleteButton
        model={model}
        doc={doc}
        dataKey={`del-${doc.id}`}
      />
    </Flex>
  )
}
