import { trModel } from 'react-hook-form-auto'
import classnames from 'classnames'

import ownStyles from './Checkbox.module.sass'

export let Checkbox = ({
  id,
  schemaTypeName,
  name,
  onChange,
  onBlur,
  value,
  defaultValue,
  inputRef,
  styles = {},
  field 
}) => {
  const defaultChecked = defaultValue !== 'false' && defaultValue
  const wrapClasses = classnames(
    styles.checkboxWrap,
    ownStyles.container,
    {
      [ownStyles.checked]: value,
      [ownStyles.unchecked]: !value,
    }
  )

  return (
    <div key={name} className={wrapClasses}>
      <input
        key="val"
        id={id}
        type="checkbox"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        defaultChecked={defaultChecked}
        className={styles.checkbox}
        ref={inputRef}
      />
      <label
        key="label"
        htmlFor={id}
        className={styles.checkboxLabel}
      >
        {trModel(schemaTypeName, name)}
      </label>
    </div>
  )
}

