import Flex from '@react-css/flex'
import { Patient } from './Patient'
import classnames from 'classnames'

export const DisplayPatients = ({
  doc,
  value,
  contents,
  ...rest
}) => {
  return (
    <Flex row wrap>
      {
        value.map((patient, idx) => {
          const patientContents = contents[idx]

          return (
            <Patient
              key={patient.id}
              idx={idx}
              client={doc}
              patient={patient}
              contents={patientContents}
              {...rest}
            />
          )
        })
      }
    </Flex>
  )
}
