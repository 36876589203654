import { DateRange } from './input/DateRange'
import { DateInput } from './input/DateInput'
import { Checkbox } from './input/Checkbox'
import { FileInput } from './input/FileInput'

export const skin = {
  dateRange: {
    controlled: true,
    render: {
      component: DateRange,
    }
  },
  date: {
    controlled: true,
    render: {
      component: DateInput
    }
  },
  boolean: {
    coerce: value => Boolean(value),
    controlled: true,
    props: (props) => {
      return {
        ...props,
        component: Checkbox,
        inline: true
      }
    }
  },
  file: {
    component: FileInput
  }
}
