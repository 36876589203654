import { objectExtract } from '../utils/path_traverse'
import { resourceName, fieldHighlight } from './crudUtils'
import { LinkToResource } from './display/LinkToResource'

import { DisplayID } from './display/DisplayID'
import { DisplayNumber } from './display/DisplayNumber'
import { DisplayPictures } from './display/DisplayPictures'
import { DisplayPrice } from './display/DisplayPrice'
import { DisplayChangeablePrice } from './display/DisplayChangeablePrice'
import { DisplayTax } from './display/DisplayTax'
import { DisplayBoolean } from './display/DisplayBoolean'
import { DisplayDate } from './display/DisplayDate'
import { DisplayEmail } from './display/DisplayEmail'
import { DisplayPatients } from './display/DisplayPatients'
import { DisplayPatient } from './display/DisplayPatient'
import { DisplayAssociated } from './display/DisplayAssociated'
import { DisplayIngredients } from './display/DisplayIngredients'
import { DisplayLabel } from './display/DisplayLabel'
import { DisplayStringCollapse } from './display/DisplayStringCollapse'
import { DisplayText } from './display/DisplayText'
import { DisplayPrivateText } from './display/DisplayPrivateText'

import styles from './DisplayListField.module.sass'

const nameHighlighter = ({ model, doc, fieldPath }) =>
  `${fieldPath}.${model.namePath}`

const displayers = {
  id: {
    component: DisplayID
  },
  string: {
    component: DisplayText
  },
  text: {
    component: DisplayText
  },
  collapseText: {
    component: DisplayStringCollapse
  },
  number: {
    component: DisplayNumber
  },
  name: {
    component: LinkToResource
  },
  pictures: {
    component: DisplayPictures
  },
  price: {
    component: DisplayPrice
  },
  changeable_price: {
    component: DisplayChangeablePrice
  },
  associated: {
    highlightPath: nameHighlighter,
    component: DisplayAssociated
  },
  ingredients: {
    component: DisplayIngredients
  },
  tax: {
    component: DisplayTax
  },
  boolean: {
    component: DisplayBoolean
  },
  date: {
    component: DisplayDate
  },
  email: {
    component: DisplayEmail
  },
  patients: {
    highlightPath: nameHighlighter,
    component: DisplayPatients
  },
  patient: {
    component: DisplayPatient
  },
  label: {
    component: DisplayLabel
  },
  private_text: {
    component: DisplayPrivateText
  }
}

export const DisplayListField = ({
  model,
  fieldSchema,
  fieldName,
  doc,
  highlight,

  ...rest
}) => {
  const { pathInDocument, valueProcess, addProps } = fieldSchema
  const fieldPath = typeof pathInDocument == 'undefined' ?
    fieldName : pathInDocument
  const fieldValue = objectExtract(doc, fieldPath)
  const type = fieldSchema.listType || fieldSchema.type
  const displayer = displayers[type || 'string']
  if (!displayer) {
    console.error(
      'There is not a displayer in DisplayListField for',
      fieldName,
      'with listType or type',
      type
    )
  }
  const $display = displayer.component

  const highlightedComponent = fieldHighlight({
    model,
    doc,
    highlight,
    fieldSchema,
    displayer,
    fieldPath,
    fieldValue
  })

  const transformedDoc = valueProcess ? valueProcess(fieldValue, doc) : doc
  const moreProps = addProps ? addProps(fieldValue, doc) : {}

  return (
    <div className={styles.container}>
      <$display
        {...rest}
        model={model}
        fieldSchema={fieldSchema}
        fieldName={fieldName}
        fieldPath={fieldPath}
        value={fieldValue}
        doc={transformedDoc}
        contents={highlightedComponent}
        {...moreProps}
      />
    </div>
  )
}
