import { useRef } from 'react'
import { debounce } from '../utils/utils'

export function useDebouncer(f, time = 100) {
  const debouncedRef = useRef(debounce(f, time))

  return {
    // Update callback without destroying the debounce reference
    updateCallback(newCallback) {
      debouncedRef.current.updateFunction(newCallback)
    },

    send(data) {
      debouncedRef.current.send(data)
    }
  }
}
