export const isImage = extension => /png|jpg|jpeg/i.test(extension)

export function mimetypeIsImage(mimetype) {
  if (mimetype) {
    const parts = mimetype.split('/')
    if (parts.length > 1)
      return isImage(parts[1])
    else
      return false
  } else
    return true
}
