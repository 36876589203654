import { pathForModel } from './crudUtils'
import { Button } from '../bootstrap'
import { tr } from '../translator'

export const EditButton = ({
  model,
  doc
}) => {
  const editPath = pathForModel({ model, doc, action: 'edit' })
  const editText = tr('helpers.links.edit')

  return (
    <Button
      key="edit"
      icon="edit"
      href={editPath}
      text={editText}
      mini
    />
  )
}
