import { objectExtract } from '../utils/path_traverse'
import { tr, stringExists } from '../translator'

export function getFieldName(model, fieldName) {
  const pre = 'simple_form.labels'
  const path = model.modelName || model.name
  const direct = `${pre}.${path}.${fieldName}`

  if (stringExists(direct)) {
    return tr(direct)
  } else {
    const def = `${pre}.defaults.${fieldName}`
    return tr(def)
  }
}

export function idFromDoc({ model, doc }) {
  return doc.id
}

export function pathForModel({ model, doc, action }) {
  const pre = model.indexPath
  const id = idFromDoc({ model, doc })

  switch (action) {
  case 'show':
  case 'delete':
    return `${pre}/${id}`
  case 'edit':
    return `${pre}/${id}/edit`
  }
}

export function resourceName(model, doc) {
  const { namePath, namer } = model

  if (namePath || namer) {
    const finalNamer = namer || (doc => objectExtract(doc, namePath))

    return finalNamer(doc)
  }
}

const groupHighlightRE = new RegExp('</em> <em>', 'g')

// Returns the highlight <span>
export function highlightedComponent(highlight) {
  if (typeof highlight == 'number')
    highlight = highlight.toString()

  if (typeof highlight == 'string') {
    // This removes the spaces between <em />
    const highlighted = (highlight || '').replace(groupHighlightRE, ' ')

    return (
      <span dangerouslySetInnerHTML={{ __html: highlighted }} />
    )
  } else
    return ''
}

const removeTagsRegEx = new RegExp(/<[^>]*>/, 'g')

export function removeSearchTags(arr) {
  arr = Array.isArray(arr) ? arr : [arr]
  return arr.map(hi => {
    return (hi || '').replace(removeTagsRegEx, '')
  })
}

// For array fields
function guidedArrayExtract({
  fieldHighlight,
  arrayHighlight,
  fieldValue,
  plains
}) {
  return fieldValue.map(elem => {
    const docName = arrayHighlight(elem)

    if (fieldHighlight.length > 0) {
      const plainIdx = plains.indexOf(docName)
      if (plainIdx == -1)
        return highlightedComponent(docName)
      else
        return highlightedComponent(fieldHighlight[plainIdx])
    } else
      return docName
  })
}

// Prepares highlighting
export function extractAndHighlight({
  fieldHighlight,
  arrayHighlight,
  fieldValue,
  highlightPath,
  doc
}) {
  const plains = removeSearchTags(fieldHighlight)

  if (arrayHighlight) {
    return guidedArrayExtract({
      fieldHighlight,
      arrayHighlight,
      fieldValue,
      plains
    })
  }

  let highlighted
  if (fieldHighlight && fieldHighlight.length > 0) {
    highlighted = fieldHighlight[0]
  } else {
    highlighted = objectExtract(doc, highlightPath)
    if (Array.isArray(highlighted) && highlighted.length == 0)
      highlighted = ''
  }

  return highlightedComponent(highlighted)
}

// From model/crud
export function fieldHighlight({
  model,
  doc,
  highlight,
  fieldSchema,
  displayer,
  fieldPath,
  fieldValue
}) {
  const highlightPather = fieldSchema.highlightPath
    || displayer.highlightPath
    || (({ fieldPath }) => fieldPath)

  const highlightPath = highlightPather({
    fieldPath,
    fieldValue,
    model,
    doc,
    fieldSchema
  })

  const fieldHighlight = (highlight && highlight[highlightPath]) || []

  return extractAndHighlight({
    arrayHighlight: fieldSchema.arrayHighlight,
    fieldHighlight,
    fieldValue,
    highlightPath,
    doc
  })
}
