import classnames from 'classnames'

import styles from './DisplayNumber.module.sass'

export const DisplayNumber = ({ contents, doc, value, fieldSchema }) => {
  const classes = classnames(
    styles.number,
    fieldSchema.displayClass && fieldSchema.displayClass({ doc, value })
  )

  return (
    <span className={classes}>
      {contents}
    </span>
  )
}
