import styles from './DisplayIngredients.module.sass'

export const DisplayIngredients = ({
  fieldName,
  value = null
}) => {
  if (value) {
    return (
      <ul key={fieldName} className={styles.wrapper}>
        {
          value.map(principio =>
            <li key={principio.id}>
              {principio.name}
            </li>
          )
        }
      </ul>
    )
  } else
    return null
}
