import { useState } from 'react'

import {
  Input,
  Button,
  Navbar,
  NavbarItem
} from '../bootstrap'
import { arrayReplace } from '../utils/utils'
import { DiscountEditDest } from './DiscountEditDest'
import { DiscountEditMultiple } from './DiscountEditMultiple'
import { tr } from '../translator'

const menu = [
  {
    value: 'dest',
    label: 'discount_edit_dest',
    component: DiscountEditDest
  },
  {
    value: 'multi',
    label: 'discount_edit_multi',
    component: DiscountEditMultiple
  }
]

export const DetailedDiscount = (props) => {
  const [ option, setOption ] = useState('dest')

  const selectedMenu = menu.find(entry => entry.value == option)
  const $component = selectedMenu && selectedMenu.component || null

  return (
    <>
      <Navbar>
        {
          menu.map(entry =>
            <NavbarItem
              key={entry.value}
              onClick={() => setOption(entry.value)}
              selected={option == entry.value}
            >
              {tr(`vetus.movimientos.${entry.label}`)}
            </NavbarItem>
          )
        }
      </Navbar>
      <$component {...props} />
    </>
  )
}
