import {
  movimientoFilterSchema,
  initialFilterState
} from '../models/movimiento'
import { Autoform } from '../ui/Autoform'

export const MovimientoIndexFilters = ({
  state,
  dispatch
}) => {
  const initialValues = initialFilterState()

  const handleChanges = (doc) => {
    dispatch({
      type: 'filter-temp',
      temporal: doc.temporal
    })
  }

  return (
    <Autoform
      schema={movimientoFilterSchema}
      initialValues={initialValues}
      onChange={handleChanges}
    />
  )
}
