import classnames from 'classnames'

import styles from './Well.module.sass'

export const Well = ({
  className,
  children,
  small,
  label
}) => {
  const classes = classnames('well', className, {
    [styles.container]: label,
    'well-small': small
  })

  return (
    <div className={classes}>
      {
        label &&
          <div className={styles.label}>
            {label}
          </div>
      }
      {children}
    </div>
  )
}
