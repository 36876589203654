import { useState } from 'react'
import Flex from '@react-css/flex'
import classnames from 'classnames'

import { Input, Button } from '../bootstrap'
import { tr } from '../translator'

import { inputToNumber, inputPriceToString } from '../utils/money'

import styles from './PriceTaxedEdit.module.sass'

export const PriceTaxedEdit = ({
  taxName,
  taxFactor = 1,
  raw,
  procesado,
  onChange,
  isProvider,
  canSubmit,
  partOfMore,
  label,
  onSubmit
}) => {
  const createChangeHandler = (process, direct) => newValue => {
    // Modified is the one the user has edited. Derived is the other
    const numericModified = inputToNumber(newValue)
    const inputModified = inputPriceToString(numericModified)
    const procResult = process(numericModified)
    const inputDerived = inputPriceToString(procResult)

    if (onChange) {
      if (direct) {
        onChange({
          raw: inputModified,
          procesado: inputDerived
        })
      } else {
        onChange({
          raw: inputDerived,
          procesado: inputModified
        })
      }
    }
  }

  const handleRawChange = createChangeHandler(money => money * taxFactor, true)
  const handleProcessedChange = createChangeHandler(money => money / taxFactor, false)

  const inputRaw = inputPriceToString(raw)
  const inputProcesado = inputPriceToString(procesado)

  const willFocus = canSubmit && !partOfMore
  const rawWillFocus = willFocus && isProvider
  const processedWillFocus = willFocus && !isProvider
 
  const handleSubmit = () => {
    if (onSubmit)
      onSubmit({ raw, procesado })
  }

  const handleKeyDown = (e) => {
    if (e.code == 'Enter' || e.code == 'NumpadEnter')
      handleSubmit()
  }

  const inputRawClasses = classnames('number-input', 'input-raw', {
    [styles.info]: isProvider
  })

  const inputProcessedClasses = classnames('number-input', 'input-processed', {
    [styles.info]: !isProvider
  })

  const inputNode = (
    <Flex row noWrap alignItemsCenter>
      <Input
        key="raw"
        className={inputRawClasses}
        onChange={handleRawChange}
        onKeyDown={handleKeyDown}
        value={inputRaw}
        autoFocus={rawWillFocus}
        inline
      />
      <div className={styles.taxLabel}>
        { taxName && taxFactor && `+ ${taxName} =`}
      </div>
      <Input
        key="processed"
        className={inputProcessedClasses}
        onChange={handleProcessedChange}
        onKeyDown={handleKeyDown}
        value={inputProcesado}
        autoFocus={processedWillFocus}
        inline
      />
      {
        canSubmit &&
          <Button
            className="btn-primary change-button"
            icon="check"
            text={tr('helpers.links.submitGeneric')}
            onClick={handleSubmit}
            small
          />
      }
    </Flex>
  )

  return (
    <Input
      label={label}
      inputNode={inputNode}
    />
  )
}
