import { useState, useEffect } from 'react'

import { Popover, Button } from '.'

// Content will be on children. Button is managed by this,
// but its props can be specified by buttonProps
export const PopoverButton = ({
  children,
  buttonProps,
  onOpen,
  onClose,
  visible,
  ...rest
}) => {
  const [ open, setOpen ] = useState(visible)
  const finalOpen = typeof visible == 'undefined' ? open : visible

  const handleOpen = () => {
    setOpen(true)
    if (onOpen)
      onOpen()
    if (buttonProps && buttonProps.onClick)
      buttonProps.onClick()
  }

  const handleClose = () => {
    setOpen(false)
    if (onClose)
      onClose()
  }

  return (
    <Popover
      {...rest}
      isOpen={finalOpen}
      content={children}
      onWantToClose={handleClose}
    >
      <Button
        {...buttonProps}
        onClick={handleOpen}
        disabled={finalOpen}
      />
    </Popover>
  )
}
