import { useState } from 'react'

import {
  NumberInput,
  Button,
  Popover
} from '../bootstrap'
import { tr } from '../translator'

import { DetailedPrice } from '../ui/DetailedPrice'
import { SetupPayments } from './SetupPayments'

export const PaymentTypeEdit = ({
  type,
  payments,
  label,
  total,
  onChange,

  ...rest
}) => {
  const [imposing, setImposing ] = useState(false)

  const factor = payments[type] || 0

  const handleChange = (newPercent) => {
    onChange({
      ...payments,
      [type]: newPercent / 100
    })
  }

  const handleTop = () => {
    onChange({
      [type]: 1
    })
  }

  const openSetupPayments = () => {
    setImposing(true)
  }

  const closeSetupPayments = () => {
    setImposing(false)
  }

  const interceptSetupChange = (doc) => {
    if (onChange)
      onChange(doc)
  }

  const setupPayments = (
    <SetupPayments
      {...rest}
      type={type}
      payments={payments}
      total={total}
      onChange={onChange}
    />
  )
  console.log("DETAILED PAYMENT total", total, "x factor", factor)

  return (
    <tr>
      <td key="percent">
        <NumberInput
          value={factor * 100}
          onChange={handleChange}
          wantNumber
          addOn="%"
          inline
        />
      </td>
      <td key="total">
        <DetailedPrice
          procesado={total * factor}
          icon="arrow-left"
          text={label}
          onClick={handleTop}
        />
      </td>
      <td key="allin">
        <Popover
          isOpen={imposing}
          positions={['right', 'top', 'bottom']}
          padding={10}
          header={tr(`vetus.movimientos.setup_${type}`)}
          content={setupPayments}
          onWantToClose={closeSetupPayments}
        >
          <Button
            icon="cog"
            onClick={openSetupPayments}
            label={tr(`vetus.movimientos.setup_${type}`)}
            data-key={`pay-${type}`}
          />
        </Popover>
        <Button
          icon="arrow-left"
          text={label}
          onClick={handleTop}
          data-key={`all-${type}`}
        />
      </td>
    </tr>
  )
}
