import { stringify } from '../utils/utils'
import { navigateTo } from '../utils/http'

const debugData = true

// We will use vetus convention so:
// params = { name: 'Pepe', areas: [ 12, 14 ] }
// will produce:
//
// name = 'Pepe'
// areas_0 = 12
// areas_1 = 14
//
function processParams(params) {
  if (params) {
    const keys = Object.keys(params)
    return keys.reduce((result, key) => {
      const value = params[key]
      if (Array.isArray(value)) {
        value.forEach((elem, idx) => {
          const name = `${key}_${idx}`
          result[name] = elem
        })
      } else
        result[key] = value

      return result
    }, {})
  } else
    return {}
}

function getTokenFromPage() {
  const selector = '[name~=csrf-token][content]'
  const tokenItem = document.head.querySelector(selector)
  return tokenItem && tokenItem.content || ''
}

function getTokenHeader() {
  const token = getTokenFromPage()
  
  return { 'X-CSRF-Token': token }
}

export function fetchWithVetus(url, params) {
  const processed = processParams(params)
  const fullUrl = url + '?' + new URLSearchParams(processed)
  return fetch(fullUrl)
    .then(response => {
      return response.json()
    }).then(data => {
      if (debugData)
        console.log("Vetus fetch", url, "params", params, "result", data)

      return data
    })
}

// Don't be me: id is already in url. It doesn't need to be in params.
export function deleteFromVetus(url, params = [], options = {}) {
  return postToVetus(url, [
    ...params,
    [ '_method', 'delete' ],
    [ 'authenticity_token', getTokenFromPage() ]
  ], {
    ...options,
    skipDebug: true,
    redirect: true
  })
    .then(response => {
      if (debugData)
        console.log(
          "Vetus delete",
          url,
          "params",
          params,
          "result",
          response || ""
        )

      return response
    })
}

export function fetchStorePrice(art_id) {
  return fetchWithVetus(`/arts/${art_id}/precio_store.json`)
}

export async function postToVetus(url, params, options = {}) {
  try {
    const moreParams = options.multipart ? params : [
      [ 'utf8', '✓' ],
      ...params
    ]

    let data
    if (options.multipart) {
      data = new FormData(params)
    } else {
      const stringized = moreParams.map(pair => {
        const key = pair[0]
        const value = pair[1]

        return [ key, stringify(value) ]
      })

      data = new URLSearchParams(stringized)
    }

    let fetchParams = {
      method: 'POST',
      mode: 'cors',
      credentials: 'same-origin',
      headers: {
        ...getTokenHeader()
      },
      body: data
    }
    if (!options.redirect)
      fetchParams.redirect = 'manual'

    const result = await fetch(url, fetchParams)

    if (result && result.ok) {
      // If we redirect, there's no point on calling
      // alert.
      const willRedirect = options.redirect && result.redirected
      if (willRedirect)
        navigateTo(result.url)
      else if (options.alert)
        options.alert(result)

      const body = await result.json()
      if (debugData && !options.skipDebug)
        console.log("Vetus post", url, "params", params, "result", body)

      return {
        ok: result.ok,
        status: result.status,
        statusText: result.statusText,
        body
      }
    } else {
      if (options.alert)
        options.alert(result, { error: true })

      return result
    }
  } catch (err) {
    if (options.alert)
      options.alert({ error: err }, { failure: true })

    console.error('Fetch error', url, params)
    console.error('Error details', err)
  }
}

// Converts object like { info: {
//    name: 'pepe',
//    letters: [ 'a', 'b' ]
// } }
//
// to array for REST like
//
// [
//    { 'info[name]': 'pepe' },
//    { 'info[letters][]': 'a' },
//    { 'info[letters][]': 'b' }
// ]
//
// or something
//
export function objectToRequest(obj, list = [], nameBase = null) {
  const keys = Object.keys(obj)
  keys.forEach(key => {
    const name = nameBase === null ? key : `${nameBase}[${key}]`
    const value = obj[key]

    if (typeof value == 'object' && value !== null) {
      if (Array.isArray(value)) {
        value.forEach(el => {
          list.push([ `${name}[]`, stringify(el) ])
        })
      } else {
        objectToRequest(value, list, name)
      }
    } else {
      list.push([ name, stringify(value) ])
    }
  })

  return list
}

export function relativeToParam(rel, relId) {
  if (rel && relId)
    return `${rel}_id=${relId}`
}
