export const Picture = {
  indexPath: '/pictures',

  fields: {
    brief: {
      type: 'string',
    },
    filename_f: {
      type: 'file'
    }
  },

  namePath: 'original'
}
