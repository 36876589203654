import {
  Input
} from '../bootstrap'
import { postToVetus } from '../utils/fetch_with_alert'
import { AreaArtEdit } from './AreaArtEdit'
import { PriceTaxedEditContainer } from './PriceTaxedEditContainer'
import { checkAACreated } from '../utils/movimientos'
import { deepmerge } from '../utils/utils'
import { priceRead } from '../utils/money'

export const AreaArtEditContainer = ({
  areaArt = {},
  area = {},
  art = {},
  onChange,
  ...rest
}) => {
  const createDetails = [
    [ 'create_area_art[area_art_id]', areaArt && areaArt.id ],
    [ 'create_area_art[art_id]', art.id ],
    [ 'create_area_art[area_id]', area.id ]
  ]

  const handleTaxChange = async (newImpuesto) => {
    try {
      const result = await postToVetus('/taxes.json', [
        [ 'tax[taxable_id]', areaArt && areaArt.id ],
        [ 'tax[taxable_type]', 'AreaArt' ],
        [ 'tax[impuesto_id]', newImpuesto.id ],
        ...createDetails
      ])

      const addAACreated = checkAACreated(result)

      if (onChange) {
        const { cantidad } = newImpuesto
        const factor = cantidad && (parseFloat(cantidad) / 100 + 1) || 1

        const newDoc = deepmerge(addAACreated, {
          area_art: {
            procesado: areaArt && areaArt.raw * factor,
            tax: {
              ...newImpuesto,
              factor
            }
          }
        })

        onChange(newDoc)
      }
    } catch (err) {
      console.error('Error changing tax', err)
    }
  }

  const handlePriceChange = async (data) => {
    try {
      const result = await postToVetus('/precios.json', [
        [ 'precio[area_art_id]', areaArt && areaArt.id ],
        [ 'precio[precio]', data.raw ],
        ...createDetails
      ])

      const addAACreated = checkAACreated(result)

      if (onChange) {
        const newDoc = deepmerge(addAACreated, {
          area_art: {
            ...data,
            raw: priceRead(data.raw),
            procesado: priceRead(data.procesado)
          }
        })
        onChange(newDoc)
      }
    } catch (err) {
      console.error('Error changing price', err)
    }
  }

  const handlePriceBoxChange = async (data) => {
    try {
      const result = await postToVetus('/precio_boxes.json', [
        [ 'precio_box[area_art_id]', areaArt && areaArt.id ],
        [ 'precio_box[precio]', data.raw ],
        ...createDetails
      ])

      const addAACreated = checkAACreated(result)

      if (onChange) {
        const newDoc = deepmerge(addAACreated, {
          area_art: {
            raw_box: priceRead(data.raw),
            procesado_box: priceRead(data.procesado)
          }
        })
        onChange(newDoc)
      }
    } catch (err) {
      console.error('Error changing box price', err)
    }
  }

  return (
    <AreaArtEdit
      {...rest}
      areaArt={areaArt}
      area={area}
      art={art}
      onTaxChange={handleTaxChange}
      onPriceChange={handlePriceChange}
      onPriceBoxChange={handlePriceBoxChange}
    />
  )
}
