import { createSchema } from 'react-hook-form-auto'
import { MemoIndexFilters } from '../memos/MemoIndexFilters.jsx'
import styles from './memo.module.sass'
import { add } from 'date-fns'
import { dateToDisplay } from '../utils/date'
import { lfToHTMLBr } from '../utils/utils'
import { tr } from '../translator'

const memoTypeOptions = [
  { value: '0', key: 'none' },
  { value: '1', key: 'vacuna' },
  { value: '2', key: 'desparasita' },
  { value: '3', key: 'consulta' },
  { value: '4', key: 'muerte' },
  { value: '5', key: 'varios' },
  { value: '6', key: 'pelu' }
]

export const memoFilterSchema = createSchema('memoFilters', {
  fromTo: {
    type: 'dateRange'
  },
  type: {
    type: 'select',
    options: memoTypeOptions
  }
})

const initialFilterState = (defaultInitial = {}) => {
  const date = new Date()

  return {
    ...defaultInitial,
    filterFrom: add(date, { weeks: -1 }),
    filterTo: add(date, { days: 1 }),
    filterType: null
  }
}

export const Memo = {
  indexPath: '/memos',

  fields: {
    created_at: {
      type: 'date',
      strong: true
    },
    memo_type: {
      type: 'label',
      labels: memoTypeOptions,
      labelStyle: styles.label,
      labelStyles: styles,
      labelIcons: {
        0: null,
        1: 'plane',
        2: 'screenshot',
        3: 'comment',
        4: 'tag',
        5: 'info-sign',
        6: 'leaf'
      },
      labelText: type => tr(`vetus.memos.types.${type}`)
    },
    pictures: {
      type: 'pictures'
    },
    texto: {
      type: 'text',
      crudListPreprocess: (doc) => {
        return {
          ...doc,
          texto: lfToHTMLBr(doc.texto)
        }
      }
    },
    private: {
      type: 'text',
      listType: 'private_text'
    },
    paciente: {
      type: 'patient',
      highlightPath: () => 'paciente.name',
      listShowCondition: ({ embeded }) => {
        return !embeded
      }
    }
  },

  filter: {
    reducer: (defaultReducer) => {
      return function memoFilter(state, action) {
        switch (action.type) {
        case 'filter-update':
          {
            const { doc } = action

            return {
              ...state,
              filterFrom: doc.fromTo.start,
              filterTo: doc.fromTo.end && add(doc.fromTo.end, { days: 1 }),
              filterType: doc.type || 0,
              firstFetch: false
            }
          }
        case 'filter-reset':
          return initialFilterState()
        default:
          return defaultReducer(state, action)
        }
      }
    },
    initial: initialFilterState,
    params: (state) => {
      return {
        from: state.filterFrom && dateToDisplay(state.filterFrom),
        to: state.filterTo && dateToDisplay(state.filterTo),
        'type[]': state.filterType || 0,
        direction: 'desc',
        sort: 'created_at'
      }
    },
    fetchOn: (state) => [
      state.filterFrom,
      state.filterTo,
      state.filterType
    ],
    ui: MemoIndexFilters
  },

  modelName: 'memo',
  namePath: 'paciente.name'
}
