export const Family = {
  indexPath: '/families',

  fields: {
    name: {
      type: 'name',
    }
  },

  modelName: 'family',
  namePath: 'name'
}
