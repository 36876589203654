export const Recount = {
  indexPath: '/inventariados',

  fields: {
    created_at: {
      type: 'date'
    },
    place: {
      type: 'associated',
      otherClass: 'places'
    },
    note: {
      type: 'string'
    },
    procesado: {
      type: 'boolean'
    },
  },

  modelName: 'inventariado',
}
