import { useRef, useState } from 'react'

import { ShowPicture } from '../../ui/ShowPicture'
import { Icon, PopoverButton, Input } from '../../bootstrap'
import { tr } from '../../translator'
import { RemotePopoverButton } from '../../bootstrap/RemotePopoverButton'
import { StaticModal } from '../../ui/StaticModal'
import { Picture } from '../../models/picture'
import { postToVetus } from '../../utils/fetch_with_alert'

import styles from './DisplayPictures.module.sass'

export const DisplayPictures = ({
  value,
  model,
  doc
}) => {
  const [ files, setFiles ] = useState(value)
  const [ createOpen, setCreateOpen ] = useState(false)
  const formRef = useRef()

  const buttonProps = {
    className: 'btn-success',
    icon: 'picture',
    text: tr('vetus.add'),
    mini: true
  }

  const relIdParam = `${model.modelName}_id=${doc.id}`
  const createUrl = `/pictures.json?${relIdParam}`

  const handleOpen = () => {
    setCreateOpen(true)
  }

  const handleClose = () => {
    setCreateOpen(false)
  }

  const handleUpload = async (form) => {
    const result = await postToVetus(createUrl, form, {
      multipart: true
    })

    console.log("RESULT", result)
    if (result.ok) {
      setFiles([
        ...files,
        result.body
      ])
    }

    setCreateOpen(false)
  }

  const handleClickSave = (doc) => {
    handleUpload(formRef.current)
  }

  const handleSubmitPhantom = (e) => {
    e.preventDefault()
    handleClickSave()
  }

  return (
    <>
      {
        files.length > 0 &&
          <ul className="thumbnails">
            {
              files.map(pic =>
                <li key={pic.id} className="span2">
                  <ShowPicture
                    pic={pic}
                    editable
                  />
                </li>
              )
            }
          </ul>
      }
      <PopoverButton
        buttonProps={buttonProps}
        positions={['right', 'bottom']}
        padding={10}
        wide
        visible={createOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        noReposition
      >
        <StaticModal
          className={styles.container}
          header={tr('simple_form.labels.picture.attach')}
          onSave={handleClickSave}
          buttons={null}
        >
          <form className="form form-horizontal" ref={formRef}>
            <input type="hidden" name="utf8" value="✓" />
            <Input
              name="picture[brief]"
              label={tr('models.picture.brief')}
              uncontrolled
            />
            <Input
              name="picture[filename_f]"
              label={tr('models.picture.filename_f')}
              type="file"
              uncontrolled
            />
            <button
              type="submit"
              onClick={handleSubmitPhantom}
              style={{display: 'none'}}
              aria-hidden="true"
            />
          </form>
        </StaticModal>
      </PopoverButton>
    </>
  )
}
