import { useEffect } from 'react'

import { Button, CenteredTD } from '../bootstrap'
import { AreaArtAssocSearch } from '../area_arts/AreaArtAssocSearch'
import { AreaLabel } from '../areas/AreaLabel'
import { ChangeablePrice } from '../ui/ChangeablePrice'
import { ChangeableTax } from '../ui/ChangeableTax'
import { DetailedPrice } from '../ui/DetailedPrice'
import { tr } from '../translator'
import { fetchStorePrice } from '../utils/fetch'
import {
  getTax,
  calcMovEntTotal,
  applyMovEntDiscount,
  railsToReactAreaArt
} from '../utils/movimientos'
import { DiscountEdit } from './DiscountEdit'
import { BoxableIntegerInput } from '../ui/BoxableIntegerInput'
import { fetchWithVetus } from '../utils/fetch'
import { postToVetus } from '../utils/fetch_with_alert'
import { deepmerge } from '../utils/utils'

import styles from './MovEntEdit.module.sass'

export const MovEntEdit = ({
  idx,
  movEnt,
  onChange,
  onRemove,
  setResults,
  selectResult,
  areas,
  areasOrg,
  areaDest,
  storeArea,
  isFactura,
  taxes,

  index,
  numMovEnts
}) => {
  const handleSearchSelect = async (idx, doc = {}) => {
    try {
      if (doc.bring_to_area) {
        const someOrg = areasOrg && areasOrg[0] // Pure science
        const createdAA = await postToVetus('/area_arts.json', [
          [ 'area_art[art_id]', doc.art.id ],
          [ 'area_art[area_id]', someOrg.value ]
        ])

        if (createdAA.ok) {
          const jsonedAA = createdAA.body
          if (jsonedAA) {
            selectResult(0, {
              ...jsonedAA,
              searching: jsonedAA.art && jsonedAA.art.name,
              cantidad: 1
            })

            console.log("INCORPORATED AA", jsonedAA)
          }
        }
      } else {
        selectResult(idx)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const {
    art = {},
    area,
    area_art,
    store_aa,
    searching,
    results
  } = movEnt

  useEffect(async () => {
    try {
      if (isFactura && movEnt.art && movEnt.art.id) {
        const newStoreAA = await fetchStorePrice(movEnt.art.id)

        onChange({
          store_aa: newStoreAA,
          has_store_area_art: true
        })
      }
    } catch(err) {
      console.error('Error fetching store_aa', err)
    }
  }, [ art.id  ])

  const area_art_id = area_art && area_art.id
  const hasStuff = area_art_id

  // Forced / own tax?
  const { tax, isForcedTax } = getTax(area_art, area, movEnt.impuesto)

  const handlePriceChange = (newValue) => {
    onChange(newValue)
  }

  const handleTaxChange = (newImpuesto) => {
    onChange({
      impuesto: newImpuesto
    })
  }

  const handlePvpChange = (newValue) => {
    const { store_aa, ...rest } = movEnt

    onChange({
      store_aa: {
        ...store_aa,
        ...newValue
      }
    })
  }

  const handleDescuentoChange = (descuento) => {
    onChange({
      descuento
    })
  }

  const handleChangeAmount = (newAmount) => {
    onChange({
      cantidad: newAmount
    })
  }

  console.log("MV ENT", movEnt)
  const total = calcMovEntTotal(movEnt, { isProvider: isFactura })
  const totalDiscounted = total && applyMovEntDiscount(movEnt, total)
  console.log("MOV ENT TOTAL DISCOUNTED", totalDiscounted)

  return (
    <tr>
      <CenteredTD key="actions">
        <Button
          text={tr('helpers.links.remove')}
          icon="trash"
          className="btn-danger"
          small
          onClick={onRemove}
        />
      </CenteredTD>

      <CenteredTD key="origin">
        {
          movEnt.area && movEnt.area.name &&
            <AreaLabel areaName={movEnt.area.name} />
        }
      </CenteredTD>

      <td key="search">
        <AreaArtAssocSearch
          className={styles.search}
          name={`area-art-search-${idx}`}
          value={art && art.name}
          locked={area_art_id}
          onChange={onChange}
          setResults={setResults}
          selectResult={handleSearchSelect}
          areasOrg={areasOrg}
          searching={searching}
          results={results}
          isFactura={isFactura}
        />
      </td>

      <td key="tax">
        {
          hasStuff &&
            <ChangeableTax
              areaArt={area_art}
              area={area}
              taxes={taxes}
              onChange={handleTaxChange}
              forceTax={movEnt.impuesto}
              keyForData={`force-tax-${idx}`}
            />
        }
      </td>

      <td key="price">
        {
          hasStuff &&
            <ChangeablePrice
              areaArt={area_art}
              area={area}
              art={art}
              taxes={taxes}
              forceTax={isForcedTax && tax}
              onChange={handlePriceChange}
              rawOverProcessed={isFactura}
              keyForData={`price-${idx}`}
            />
        }
      </td>

      {
        isFactura &&
          <td key="pvp">
            {
              hasStuff &&
                <ChangeablePrice
                  areaArt={store_aa && store_aa.area_art}
                  area={storeArea}
                  art={art}
                  taxes={taxes}
                  onChange={handlePvpChange}
                  keyForData={`pvp-${idx}`}
                />
            }
          </td>
      }

      <td key="discount">
        {
          hasStuff &&
            <DiscountEdit
              descuento={movEnt.descuento}
              taxName={tax.name}
              taxFactor={tax.factor}
              raw={area_art.raw}
              procesado={area_art.procesado}
              cantidad={movEnt.cantidad}
              onChange={handleDescuentoChange}
              idx={idx}
              tabIndex={index + 1}
            />
        }
      </td>

      <td key="amount">
        {
          <BoxableIntegerInput
            value={movEnt.cantidad}
            movEnt={movEnt}
            onChange={handleChangeAmount}
            data-key={`amount-${idx}`}
            inline
            tabIndex={index + 1 + numMovEnts}
            isFactura={isFactura}
          />
        }
      </td>

      <td key="sum">
        <DetailedPrice
          raw={totalDiscounted && totalDiscounted.raw}
          procesado={totalDiscounted && totalDiscounted.procesado}
          taxName={tax && tax.name}
          rawOverProcessed={isFactura}
          isForcedTax={isForcedTax}
          onlyRaw={isFactura}
        />
      </td>
    </tr>
  )
}
