// Returns number
export function priceRead(price, def) {
  if (typeof price != 'undefined') {
    const vanilla = typeof price == 'string' ?
      price.replace(',', '.') : price
    if (vanilla !== '') {
      const floater = parseFloat(vanilla)
      if (!isNaN(floater))
        return floater
    }
  } else {
    return def
  }
}

// Returns integer number
export function integerRead(num, def) {
  if (typeof num != 'undefined') {
    const vanilla = typeof num == 'string' ?
      parseInt(num) : num
    if (vanilla !== '') {
      const floater = parseFloat(vanilla)
      if (!isNaN(floater))
        return floater
    }
  } else {
    return def
  }
}

// String for server, with dot.
export function numberToCannonical(amount) {
  const vanilla = priceRead(amount)
  if (typeof vanilla != 'undefined')
    return vanilla.toFixed(4)
}

// Tax is object with rails Tax.index_schema
export function applyTax(price, tax) {
  const taxed = price * tax.factor
  return numberToCannonical(taxed)
}

export function priceProcess(x)
{
  const vanilla = priceRead(x)
  if (typeof vanilla != 'undefined')
    return parseFloat(vanilla.toFixed(2))
}

export function priceToString(x, roundable)
{
  const price = priceRead(x)
  if (typeof price != 'undefined') {
    let priceS = price.toFixed(2)
    if (roundable)
      priceS = parseFloat(priceS).toString()

    return priceS.replace('.', ',')
  }
}

export function inputPriceRead(x)
{
  const vanilla = priceRead(x)
  if (typeof vanilla != 'undefined')
    return vanilla.toFixed(4)
}

export function inputToNumber(x)
{
  const vanilla = inputPriceRead(x)
  if (typeof vanilla != 'undefined')
    return parseFloat(vanilla)
}

export function inputPriceToString(x)
{
  // In the inputs we can take the luxury to round them
  // FIXME No, because dot. You wouldn't be able to enter
  // decimals. One way to solve it is to check for the last
  // char of x if x is a string
  // const priceRaw = inputPriceRead(x)
  // const priceS = parseFloat(priceRaw).toString()

  const priceS = inputPriceRead(x)
  if (typeof priceS != 'undefined')
    return priceS.replace('.', ',')
  else
    return ''
}

// Supports string values (uses priceRead)
export function discountToFactor(value)
{
  const descuentoRaw = priceRead(value)
  return descuentoRaw ? descuentoRaw / 100 : 0
}

// Supports string values (uses priceRead)
export function applyDiscount(discount, money)
{
  const factor = 1 - discountToFactor(discount)
  return money * factor
}

export const SHOW_GORY_DETAILS = false

export function roundForShow(amount)
{
  const processed = priceProcess(amount)
  const integer = parseInt(processed)
  const allDecimals = processed - integer
  const cents = allDecimals * 100
  const thirdDecimal = parseInt((cents % 1) * 10)
  const roundedDecimals = (SHOW_GORY_DETAILS || thirdDecimal == 5) ?
    parseInt(cents) : Math.round(cents)
  const decimalsS = roundedDecimals < 10 ?
    `0${roundedDecimals}` : roundedDecimals.toString()

  const moreDecimals = SHOW_GORY_DETAILS && parseInt((cents * 100 % 1) * 100)
  const moreDecimalsS = SHOW_GORY_DETAILS && (moreDecimals < 10 ?
    `0${moreDecimals}` : moreDecimals.toString())

  return {
    integer,
    decimalsS,
    moreDecimalsS
  }
}

export function roundForShowPlain(amount)
{
  const { integer, decimalsS } = roundForShow(amount)

  return `${integer},${decimalsS} €`
}
