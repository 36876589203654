import { useState } from 'react'

import { DetailedPrice } from './DetailedPrice'
import { AreaArtEditContainer } from '../area_arts/AreaArtEditContainer'
import { Button, Popover, Icon, Well } from '../bootstrap'
import { tr } from '../translator'
import { getTax } from '../utils/movimientos'
import { applyTax } from '../utils/money'
import { isStore, isProviderFromName } from '../utils/special_areas'
import { NotSoldable } from './NotSoldable'

import styles from './ChangeablePrice.module.sass'

export const ChangeablePrice = ({
  art = {},
  area = {},
  areaArt,
  taxes,
  forceTax,
  onChange,
  keyForData,
  displayBox,
  ...rest
}) => {
  areaArt = areaArt || {}

  const [ editing, setEditing ] = useState(false)

  const { tax, isForcedTax } = getTax(areaArt, area, forceTax)
  const isProvider = isProviderFromName(area.name)
  const isBoxable = !isProvider && art.subdiv && art.subdiv > 1

  const raw = displayBox ? areaArt.raw_box : areaArt.raw
  const procesado = applyTax(raw, tax)

  const handleClick = async (e) => {
    e.preventDefault()

    setEditing(true)
  }

  const handleClose = () => {
    setEditing(false)
  }

  const interceptChange = (doc) => {
    if (onChange)
      onChange(doc)

    if (!isBoxable)
      handleClose()
  }

  const popoverContent = editing && (
    <AreaArtEditContainer
      {...rest}
      areaArt={areaArt}
      area={area}
      art={art}
      taxes={taxes}
      forceTax={isForcedTax && tax}
      isForcedTax={isForcedTax}
      onChange={interceptChange}
    />
  )

  if (art.not_soldable && isStore(area.name)) {
    return (
      <NotSoldable />
    )
  } else {
    return (
      <Popover
        isOpen={editing}
        positions={['left', 'bottom', 'top', 'right']}
        padding={10}
        header={tr('vetus.fact.update_area_art')}
        content={popoverContent}
        onWantToClose={handleClose}
        wide
      >
        <div className={styles.container}>
          <div className={styles.details}>
            {
              art.subdiv && art.subdiv > 1 &&
                <div className={styles.subdiv}>
                  <Icon icon="th" className={styles.icon} />
                  <div className={styles.amount}>
                    {art.subdiv}
                  </div>
                </div>
            }
          </div>
          <div className={styles.price}>
            <DetailedPrice
              key="price-box"
              {...rest}
              raw={raw}
              procesado={procesado}
              taxName={tax.name}
              isForcedTax={isForcedTax}
              hideZero
            />
            {
              isBoxable && displayBox &&
                <Well
                  className={styles.whole}
                  label={tr('vetus.movimientos.price_box_whole')}
                >
                  <DetailedPrice
                    key="box"
                    {...rest}
                    raw={raw * art.subdiv}
                    procesado={procesado * art.subdiv}
                    taxName={tax.name}
                    isForcedTax={isForcedTax}
                    hideZero
                  />
                </Well>
            }
          </div>
          <Button
            className={styles.changer}
            onClick={handleClick}
            icon="wrench"
            small
            dataKey={keyForData}
          />
        </div>
      </Popover>
    )
  }
}
