import classnames from 'classnames'
import { LinkToResource } from './LinkToResource'

export const DisplayID = ({ contents, doc, model, fieldSchema }) => {
  return (
    <LinkToResource
      model={model}
      doc={doc}
      contents={contents}
    />
  )
}
