import { Patient } from './Patient'

export const DisplayPatient = ({
  doc,
  value,
  contents,
  ...rest
}) => {
  return (
    <Patient
      client={doc.cliente}
      patient={value}
      contents={contents}
      {...rest}
    />
  )
}
