import { tr } from '../translator'
import classnames from 'classnames'

import styles from './NotSoldable.module.sass'

export const NotSoldable = ({ right }) => {
  const classes = classnames(styles.unsoldable, {
    [styles.right]: right
  })

  return (
    <div className={classes}>
      {tr('simple_form.labels.art.not_soldable')}
    </div>
  )
}
