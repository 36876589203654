import { Fragment } from 'react'
import { Table } from '../bootstrap'
import { objectMap } from '../utils/utils'
import { DisplayListField } from './DisplayListField'
import { DisplayButtons } from './DisplayButtons'
import { getFieldName } from './crudUtils'
import { tr } from '../translator'
import { objectReduce } from '../utils/utils'

export const CrudList = ({
  model,
  collection,
  listHoverable,

  ...rest
}) => {
  if (collection && collection.length > 0) {
    const actualFields = objectReduce(model.fields, (result, cur, key) => {
      const allow =
        !cur.hideInList &&
        (!cur.listShowCondition || cur.listShowCondition(rest))

      if (allow)
        result[key] = cur

      return result
    }, {})

    return (
      <Table stripped hover={listHoverable} className="crud-list">
        <thead>
          <tr>
            {
              objectMap(actualFields, (schema, field) =>
                <th key={field}>
                  {getFieldName(model, field)}
                </th>
              )
            }
            <th key="buttons">
              {tr('helpers.actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {
            collection.map(({ doc, hi }, idx) => {
              const trClasses = model.listTrClasses && model.listTrClasses(doc)

              return (
                <Fragment key={doc.id}>
                  <tr className={trClasses}>
                    {
                      objectMap(actualFields, (schema, field) => {
                        if (schema.crudListPreprocess) {
                          doc = schema.crudListPreprocess(doc)
                        }

                        return (
                          <td key={field}>
                            <DisplayListField
                              {...rest}
                              model={model}
                              fieldSchema={schema}
                              fieldName={field}
                              doc={doc}
                              highlight={hi}
                            />
                          </td>
                        )
                      })
                    }
                    <td key="buttons">
                      <DisplayButtons
                        model={model}
                        doc={doc}
                      />
                    </td>
                  </tr>
                  {
                    model.indexExtraRows &&
                      model.indexExtraRows(doc, hi)
                  }
                </Fragment>
              )
            })
          }
        </tbody>
      </Table>
    )
  } else
    return null
}
