import classnames from 'classnames'

import { Icon } from './Icon'

export const Label = ({
  className,
  icon,
  children
}) => {
  const classes = classnames(
    'label',
    className
  )

  return (
    <span className={classes}>
      {
        icon &&
          <Icon className={icon} />
      }
      {' '}
      {children}
    </span>
  )
}

