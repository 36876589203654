import { useState } from 'react'
import { PopoverButton } from './PopoverButton'

export const RemotePopoverButton = ({
  url,
  buttonProps,
  positions = ['left', 'bottom']
}) => {
  const [ page, setPage ] = useState()

  const handleOpen = () => {
    if (!page) {
      fetch(url)
        .then(response =>
          response.text()
        ).then(text => {
          setPage(text)
        })
    }
  }

  const handleClose = () => {
    setPage(null)
  }

  let popoverContent = null
  if (open && page) {
    popoverContent = (
      <div
        className="modal"
        dangerouslySetInnerHTML={{ __html: page }}
      />
    )
  }

  return (
    <PopoverButton
      positions={positions}
      buttonProps={buttonProps}
      onOpen={handleOpen}
      onClose={handleClose}
      children={popoverContent}
    /> 
  )
}
