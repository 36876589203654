import { forwardRef } from 'react'
import classnames from 'classnames'
import { navigateTo } from '../utils/http'

import { Icon } from './Icon'

export let Button = ({
  className,
  icon,
  children,
  text,
  small,
  href,
  mini,
  disabled,
  onClick,
  anchor,
  dataKey,
  ...rest
}, ref) => {
  const classes = classnames('btn', className, {
    'btn-small': small,
    'btn-mini': mini,
    disabled
  })

  const handleClick = e => {
    e.preventDefault()
    if (!disabled) {
      if (href)
        navigateTo(href)
      if (onClick)
        onClick(e)
    }
  }

  const keyProp = dataKey && { 'data-key': dataKey }
  const $button = anchor ? 'a' : 'button'

  return (
    <$button
      className={classes}
      data-turbolinks={href ? 'true' : 'false'}
      href={href || '#'}
      onClick={handleClick}
      ref={ref}
      {...keyProp}
      {...rest}
    >
      <Icon className={icon} />
      {' '}{text || children}
    </$button>
  )
}

Button = forwardRef(Button)

export const CrossCloseButton = ({ onClick, ...rest }) => {
  return (
    <Button
      type="button"
      className="close"
      onClick={onClick}
    >
      &times;
    </Button>
  )
}
