import { Button } from '../bootstrap'
import { tr } from '../translator'

export const ExportButton = ({
  href
}) =>
  <Button
    icon="download"
    href={href}
    text={tr('helpers.links.export')}
    dataKey="export"
    anchor
  />
