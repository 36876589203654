import { useState, useEffect, useRef } from 'react'
import { fetchWithVetus } from '../utils/fetch'

export function useCrudFetch({
  state,
  dispatch,
  perPage,
  model,
  initialCollection,
  initialCount,
  relModelName,
  relId
}) {
  const [ collection, setCollection ] = useState(initialCollection)
  const [ count, setCount ] = useState(initialCount)
  const lastSearchRef = useRef('')

  console.log("CRUD FETCH STATE", state)

  const { search, page } = state
  const filter = model.filter || {}
  const filterFetchOn = filter && filter.fetchOn && filter.fetchOn(state) || []

  useEffect(async () => {
    if (!state.firstFetch) {
      let results
      try {
        let options = {
          search,
          page
        }

        if (relModelName && relId)
          options[`${relModelName}_id`] = relId

        if (filter.params) {
          options = {
            ...options,
            ...filter.params(state)
          }
        }

        results = await fetchWithVetus(`${model.indexPath}.json`, options)
      } catch(err) {
        console.error('Error fetching for model', model.name, search, err)
      }

      setCount(results.count)
      setCollection(results.collection)
      lastSearchRef.current = search
    }
  }, [ search, state.page, ...filterFetchOn ])

  return {
    collection, 
    count
  }
}
