import { useState } from 'react'
import { Button } from '../bootstrap'
import { tr } from '../translator'

export const SubmitButton = ({ onClick }) => {
  const [ disabled, setDisabled ] = useState(false)

  const handleSubmit = () => {
    if (!disabled) {
      setDisabled(true)
      onClick()
    }
  }

  return (
    <Button
      id="submit-button"
      key="submit"
      className="btn-primary"
      icon="ok"
      text={tr('vetus.save')}
      onClick={handleSubmit}
      disabled={disabled}
    />
  )
}
