import Flex from '@react-css/flex'
import { IntegerInput } from './IntegerInput'
import { Button, Alert, Icon } from '../bootstrap'
import { integerRead } from '../utils/money'
import { tr } from '../translator'
import styles from './BoxableIntegerInput.module.sass'

export const BoxableIntegerInput = ({ movEnt, isFactura, ...rest }) => {
  const subdiv = movEnt && movEnt.art && movEnt.art.subdiv || 1
  const isBoxable = subdiv > 1
  const value = integerRead(rest.value)
  const subdivRest = value % subdiv
  let buttonText = subdiv
  let buttonClass = 'btn-danger'
  if (value >= subdiv && subdivRest == 0) {
    buttonText = (
      <span>{subdiv} <Icon icon="ok" /></span>
    )
    buttonClass = ''
  }

  const handleBox = () => {
    const nextBox = parseInt(value / subdiv) + 1
    rest.onChange(nextBox * subdiv)
  }

  return (
    <Flex column noWrap>
      <Flex row noWrap>
        <IntegerInput {...rest} />
        {
          isBoxable &&
            <Button
              className={buttonClass}
              key="box"
              icon="th"
              onClick={handleBox}
              text={buttonText}
              small
            />
        }
      </Flex>
      {
        !isFactura && value > subdiv && subdivRest != 0 &&
          <Alert block className={styles.box} warnIcon>
            {
              tr('vetus.movimientos.imperfect_box_warning', {
                rest: value % subdiv
              })
            }
          </Alert>
      }
    </Flex>
  )
}
