import { memoFilterSchema } from '../models/memo'
import { Autoform } from '../ui/Autoform'

export const MemoIndexFilters = ({
  state,
  dispatch
}) => {
  const initialValues = {
    fromTo: {
      start: state.filterFrom,
      end: state.filterTo
    }
  }

  const handleChanges = (doc) => {
    dispatch({
      type: 'filter-update',
      doc
    })
  }

  return (
    <Autoform
      schema={memoFilterSchema}
      initialValues={initialValues}
      onChange={handleChanges}
    />
  )
}
