import DatePicker from 'react-datepicker'
import { isTest } from '../../utils/environment.js'
import { parseDate, dateFormat } from '../../utils/date'

export const DateInput = ({
  id,
  onChange,
  value
}) => {
  const handleTestChange = (e) => {
    const newValue = e.target.value
    const parsed = parseDate(newValue)
    if (!isNaN(parsed)) {
      console.log("TEST CHANGE", parsed)
      handleChange(parsed)
    }
  }

  return (
    <>
      <DatePicker
        id={id}
        dateFormat={dateFormat}
        selected={value}
        onChange={onChange}
        value={value}
      />
      {
        isTest() &&
          <input
            id="datepicker-mock-input"
            onChange={handleTestChange}
            onBlur={handleTestChange}
          />
      }
    </>
  )
}
