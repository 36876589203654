import { useState } from 'react'
import { Input } from '../../bootstrap'

export const FileInput = (props) => {
  const [ file, setFile ] = useState(null)

  const handleUpload = (file) => {
    props.formHook.setValue(props.name, file)
    if (props.onChange)
      props.onChange(file)
  }

  return (
    <Input
      {...props}
      type="file"
      onChange={handleUpload}
      uncontrolled
      required
    />
  )
}
