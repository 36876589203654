import classnames from 'classnames'

import { priceRead, priceToString } from '../utils/money'
import { TabbedPrice } from './TabbedPrice'

import styles from './DetailedPrice.module.sass'

export const DetailedPrice = ({
  className,
  raw,
  procesado,
  taxName,
  isForcedTax,
  rawOverProcessed,
  onlyRaw,
  hideZero = false
}) => {
  const readRaw = priceRead(raw)
  const readProcesado = priceRead(procesado)
  const hasRaw = typeof readRaw != 'undefined' && !isNaN(readRaw)
    && (!hideZero || readRaw)
  const hasProcessed = typeof readProcesado != 'undefined' && !isNaN(readProcesado)
    && (!hideZero || readProcesado)
  const hasTaxName = taxName
  if (hasProcessed || (hasRaw && hasTaxName)) {
    const swap = (rawOverProcessed || onlyRaw)
    const amountForBig = swap ? raw : procesado
    const amountForSmall = swap ? procesado : raw
    const sign = swap ? '-' : '+'
    const bigThing = (
      <TabbedPrice amount={amountForBig} bold={!swap} />
    )

    const smallStyles = classnames(styles.rawContainer, {
      [styles.grayed]: swap
    })
    const smallAmountStyles = classnames({
      [styles.bold]: swap
    })
    const taxClasses = classnames(styles.plusTax, {
      [styles.bold]: isForcedTax
    })
    const wrapperClasses = classnames(styles.container, className)

    const smallThing = hasTaxName && (
      <div className={smallStyles}>
        {
          swap && hasTaxName &&
            <div className={taxClasses}>
              +{taxName} =
            </div>
        }
        <div className={smallAmountStyles}>
          {priceToString(amountForSmall) || '0'}
        </div>
        {
          !swap && hasTaxName &&
            <div className={taxClasses}>
              {sign} {taxName}
            </div>
        }
      </div>
    )

    return (
      <div className={wrapperClasses}>
        {
          !onlyRaw &&
            (swap ? smallThing : bigThing)
        }
        {
          swap ? bigThing : smallThing
        }
      </div>
    )
  } else if (hasTaxName) {
    return (
      <div className={styles.justTax}>
        {taxName}
      </div>
    )
  } else
    return null
}
