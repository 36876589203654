import { objectTraverse } from '../../utils/path_traverse'
import { DetailedPrice } from '../../ui/DetailedPrice'
import { NotSoldable } from '../../ui/NotSoldable'

export const DisplayPrice = ({
  doc,
  fieldSchema,
  value = null,
  unsoldable
}) => {
  const rawFrom = fieldSchema.rawFrom || 'raw'
  const raw = objectTraverse(value, rawFrom, {
    returnValue: true
  })
  const processedFrom = fieldSchema.processedFrom || 'procesado'
  const procesado = objectTraverse(value, processedFrom, {
    returnValue: true
  })

  if (unsoldable) {
    return (
      <NotSoldable right />
    )
  } else if (value) {
    return (
      <DetailedPrice
        raw={raw}
        procesado={procesado}
        taxName={value.tax && value.tax.name}
        subdiv={doc.subdiv}
        hideZero
      />
    )
  }

  return null
}

