import Flex from '@react-css/flex'

import { Input, Button } from '../bootstrap'
import { integerRead } from '../utils/money'

export const IntegerInput = ({
  value,
  onChange,
  inline,
  ...rest
}) => {
  const numberValue = integerRead(value, 0)

  const handlePlus = () => {
    onChange(numberValue + 1)
  }

  const handleMinus = () => {
    onChange(numberValue - 1)
  }

  return (
    <Flex row noWrap>
      <Input
        {...rest}
        className="number-input span1"
        type="number"
        value={value}
        onChange={onChange}
        inline
      />
      <Button
        key="plus"
        icon="plus"
        onClick={handlePlus}
        small
      />
      <Button
        key="minus"
        icon="minus"
        onClick={handleMinus}
        small
      />
    </Flex>
  )
}
