import { AreaArtsExport } from '../area_arts/AreaArtsExport'

export const AreaArt = {
  indexPath: '/area_arts',

  fields: {
    name: {
      pathInDocument: 'art.name',
      type: 'name'
    },
    area: {
      type: 'associated',
      otherClass: 'areas'
    },
    tax: {
      pathInDocument: 'tax_no_cascade',
      type: 'tax'
    },
    cantidad: {
      type: 'number'
    },
    precio: {
      pathInDocument: '',
      type: 'changeable_price',
      box: false
    },
    precio_box: {
      pathInDocument: '',
      type: 'changeable_price',
      rawFrom: 'raw_box',
      processedFrom: 'procesado_box',
      box: true
    },
  },

  export: {
    ui: AreaArtsExport
  },

  modelName: 'areaart',

  namePath: 'art.name'
}
