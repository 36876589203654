import { Component } from 'react'

import styles from './ErrorBoundary.module.sass'

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return { error, hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo)
  }
  render() {
    if (this.state.hasError) {
      const stack = this.state.error.stack.split('\n')

      return <>
        <img src="/assets/pics/thumb/8184.jpg" />
        <h2>Unfuck this</h2>
        <div className={styles.fuck}>
          {this.state.error.message}
          <div className={styles.whereTheFuck}>
            {
              stack.map(entry =>
                <div key={entry}>{entry}</div>
              )
            }
          </div>
        </div>
      </>
    }
    return this.props.children
  }
}
