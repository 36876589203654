import {
  tr,
  stringExists,
  addLanguageTranslations,
  setLanguageByName,
  setTranslateVariableRegex,
  setTranslateReferenceRegex,
} from 'react-hook-form-auto'
import {
  registerLocale,
  setDefaultLocale
} from 'react-datepicker'

import esBase from '../../../config/locales/es.yml'
import es from '../../../config/locales/vetus.es.yml'
import dateFnsEs from 'date-fns/locale/es'

import enBase from '../../../config/locales/en.yml'
import en from '../../../config/locales/vetus.en.yml'

const VAR_REGEX = /%{(.*?)}/g
const REF_REGEX = /\${(.*?)}/g

const appLanguages = [
  { lang: 'es', base: esBase, strings: es, dateFns: dateFnsEs },
  { lang: 'en', base: enBase, strings: en },
]

appLanguages.forEach(({ lang, base, strings, dateFns }) => {
  addLanguageTranslations(lang, base[lang])
  addLanguageTranslations(lang, strings[lang])
  if (dateFns)
    registerLocale(lang, dateFns)
})

setLanguageByName('es')
setDefaultLocale('es')

setTranslateVariableRegex(VAR_REGEX)
setTranslateReferenceRegex(REF_REGEX)

export { tr, stringExists, setLanguageByName }
