import { useState } from 'react'
import { Autoform, createSchema } from '../ui/Autoform'
import { ExportButton } from '../crud/ExportButton'
import { Well } from '../bootstrap'
import { dateToDisplay } from '../utils/date'

const dateForm = createSchema('date', {
  until: {
    type: 'date'
  }
})

export const AreaArtsExport = ({
  relId
}) => {
  const [ date, setDate ] = useState(new Date())
  const initialValues = {
    until: new Date()
  }

  const dateFormatted = dateToDisplay(date)
  const href = `/areas/${relId}/area_arts.csv?date=${dateFormatted}`

  const handleChange = (dateDoc) => {
    setDate(dateDoc.until)
  }

  return (
    <Well>
      <Autoform
        schema={dateForm}
        onChange={handleChange}
        initialValues={initialValues}
        button
      />
      <ExportButton href={href} />
    </Well>
  )
}
