import { ErrorBoundary } from '../utils/ui/ErrorBoundary'
import { toast } from 'react-toastify'

toast.configure()
import 'react-toastify/dist/ReactToastify.css'

export const ViewWrap = ({ children }) =>
  <ErrorBoundary>
    {children}
  </ErrorBoundary>
