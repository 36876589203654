import { classFromAreaName } from '../utils/special_areas'

import { Label } from '../bootstrap'

export const AreaLabel = ({ areaName }) => {
  const specialClass = classFromAreaName(areaName)

  return (
    <Label className={specialClass}>
      {areaName}
    </Label>
  )
}
