export const Patient = {
  indexPath: '/clients',

  fields: {
    name: {
      type: 'name'
    }
  },

  modelName: 'paciente',
  namePath: 'name'
}
