import { useEffect } from 'react'

export function useEscape(cb) {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape')
        cb()
    }
    document.addEventListener('keydown', handleEscape)

    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
  }, [])
}
