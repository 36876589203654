import classnames from 'classnames'
import { dateTimeToDisplay } from '../../utils/date'
import styles from './DisplayDate.module.sass'

export const DisplayDate = ({
  value,
  fieldSchema
}) => {
  if (value) {
    const date = dateTimeToDisplay(value)
    const classes = classnames(styles.date, {
      [styles.strong]: fieldSchema.strong
    })

    return (
      <div className={classes}>
        {date}
      </div>
    )
  } else
    return null
}
