import { Link } from '../../ui/Link'
import { pathForModel, resourceName } from '../crudUtils'

import styles from './LinkToResource.module.sass'

export const LinkToResource = ({ model, doc, contents }) => {
  const url = pathForModel({ model, doc, action: 'show' })

  return (
    <Link className={styles.nobreak} href={url}>
      {contents || resourceName(model, doc)}
    </Link>
  )
}
