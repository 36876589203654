export const Principio = {
  indexPath: '/principios',

  fields: {
    name: {
      type: 'name',
    },
    family: {
      type: 'associated',
      otherClass: 'families'
    }
  },

  modelName: 'principio',
  namePath: 'name'
}
