import { DetailedPrice } from '../ui/DetailedPrice'
import { CenteredTD } from '../bootstrap'
import {
  removeSearchTags,
  highlightedComponent
} from '../crud/crudUtils'
import { MovEntCantidadDisplay } from './MovEntCantidadDisplay'
import { DiscountDisplay } from '../ui/DiscountDisplay'
import { MovEntOtherHighlights } from './MovEntOtherHighlights'

import styles from './MovEntTableShow.module.sass'

const areaArtPath = 'mov_ents.area_art'
const artPath = `${areaArtPath}.art`
const hiPath = `${artPath}.name`

function checkAndAdd(found, highlight, cur, path) {
  const attrPath = cur.replace(`${path}.`, '')
  
  if (attrPath != cur) {
    found.push({
      attrPath,
      contents: highlight[cur]
    })
  }
}

export const MovEntTableShow = ({
  movEnt,
  highlight
}) => {
  const { area_art } = movEnt
  const nameHighlight = highlight && highlight[hiPath]
  const art = area_art && area_art.art
  const artName = art && art.name
  const descuentoFactor = movEnt.descuento ? 1 - movEnt.descuento / 100 : 1

  let highlighted = artName
  if (nameHighlight && nameHighlight.length > 0) {
    highlighted = nameHighlight.reduce((result, cur) => {
      const plains = removeSearchTags(cur)
      if (plains == artName)
        result = highlightedComponent(cur)

      return result
    }, artName)
  }

  const highlightKeys = Object.keys(highlight)
  const otherHighlights = highlightKeys.reduce((found, cur) => {
    if (cur != hiPath) {
      checkAndAdd(found, highlight, cur, artPath)
      checkAndAdd(found, highlight, cur, areaArtPath)
    }

    return found
  }, [])

  console.log("OTHER HI", otherHighlights)

  if (area_art) {
    return (
      <tr>
        <td key="art" className={styles.movEnt}>
          {highlighted}
        </td>
        <td key="found" className={styles.movEnt}>
          <MovEntOtherHighlights otherHighlights={otherHighlights} />
        </td>
        <CenteredTD key="cantidad">
          {
            movEnt.cantidad > 1 &&
              <MovEntCantidadDisplay movEnt={movEnt} />
          }
        </CenteredTD>
        <td key="descuento">
          {
            movEnt.descuento &&
              <DiscountDisplay discount={movEnt.descuento} />
          }
        </td>
        <td key="precio">
          <DetailedPrice
            procesado={area_art.procesado * movEnt.cantidad * descuentoFactor}
          />
        </td>
      </tr>
    )
  } else
    return null
}
