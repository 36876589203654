import classnames from 'classnames'

export const Icon = ({
  className,
  icon,
  text
}) => {
  icon = icon || className
  const classes = classnames(
    icon && 'icon-' + icon,
    className
  )

  const iconComponent = <i className={classes} />
  if (text) {
    return (
      <span>
        {iconComponent}
        {` ${text}`}
      </span>
    )
  } else
    return iconComponent
}
