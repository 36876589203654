import { parse, parseISO, parseJSON, format } from 'date-fns'

export const dateFormat = "d/M/yyyy"
export const dateTimeFormat = "d/M/yyyy HH:mm"

const datesConfig = {
  year: 'numeric', month: 'numeric', day: 'numeric'
}

const dateTimeConfig = {
  ...datesConfig,
  hour: 'numeric', minute: 'numeric'
}

export function parseDate(str) {
  const dateISO = parseISO(str)
  if (isNaN(dateISO)) {
    const dateJSON = parseJSON(str)
    if (isNaN(dateJSON)) {
      console.log("TRYING TO PARSE", str)
      try {
        return parse(str, dateFormat, new Date())
      } catch {
        return NaN
      }
    } else {
      return dateJSON
    }
  } else {
    return dateISO
  }
}

export function formatDate(value, config, formatString = dateFormat) {
  const date = parseDate(value)

  return format(date, formatString)
}

export function dateTimeToDisplay(value) {
  return formatDate(value, dateTimeConfig, dateTimeFormat)
}

export function dateToDisplay(value) {
  return formatDate(value, datesConfig)
}
