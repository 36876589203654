// Search amongs options to find value and returns
// whole option
export function getSingleSelected(options, value) {
  return options.find(op => op.value == value)
}

// Input value or array of values and output
// { label, value }
export function getSelected(options, selection) {
  if (Array.isArray(selection))
    return selection.map(value => getSingleSelected(options, value))
  else
    return getSingleSelected(options, selection)
}
