import { Button } from '../bootstrap'
import { tr } from '../translator'

export const Place = {
  indexPath: '/places',

  fields: {
    name: {
      type: 'name'
    }
  },

  modelName: 'place',

  namePath: 'name',

  buttons: {
    add: [
      ({ doc, model }) =>
        <Button
          icon="barcode"
          text={tr('helpers.links.inventory')}
          className="btn-primary"
          href={`/inventariados/new?place_id=${doc.id}`}
          data-key={`recount-${doc.id}`}
        />
    ]
  }
}

