import Flex from '@react-css/flex'

import { EditButton } from '../crud/EditButton'
import { DeleteButton } from '../crud/DeleteButton'
import { Picture } from '../models/picture'
import { Icon } from '../bootstrap'

import styles from './ShowPicture.module.sass'

const THUMB_SIDE = 192
const MINI_THUMB_SIDE = 64

export const ShowPicture = ({
  pic,
  text,
  mini,
  editable,
  noBootstrap,
  noLink
}) => {
  const side = mini ? MINI_THUMB_SIDE : THUMB_SIDE
  text = text || pic.brief

  let imageComponent
  let otherLinkProps = {}
  if (pic.is_picture) {
    imageComponent = (
      <img
        className={styles.thumb}
        src={pic.full_t}
        width={side}
        height={side}
        alt={text}
      />
    )
  } else {
    imageComponent = (
      <Icon icon="file" text={pic.original} />
    )
    otherLinkProps = { download: pic.original }
  }

  const pictureComponent = (
    <>
      {
        !noLink &&
          <a href={pic.full} {...otherLinkProps}>
            {imageComponent}
          </a>
        ||
          imageComponent
      }
      {
        text &&
          <p className={styles.explain}>{text}</p>
      }
      {
        editable &&
          <Flex row noWrap justifyContent="center">
            <EditButton model={Picture} doc={pic} />
            <DeleteButton model={Picture} doc={pic} />
          </Flex>
      }
    </>
  )

  const className = noBootstrap ? styles.container : 'thumbnail'

  return (
    <div className={className}>
      {pictureComponent}
    </div>
  )
}
