import { objectTraverse } from '../../utils/path_traverse'
import { railsToReactAreaArt } from '../../utils/movimientos'
import { ChangeablePrice } from '../../ui/ChangeablePrice'

export const DisplayChangeablePrice = ({
  doc,
  fieldSchema,
  taxes,
  value
}) => {
  if (value) {
    const { box } = fieldSchema
    const { art, area, area_art } = railsToReactAreaArt(doc)

    const boxS = box ? 'box-' : ''

    return (
      <ChangeablePrice
        art={art}
        area={area}
        areaArt={area_art}
        taxes={taxes}
        displayBox={fieldSchema.box}
        keyForData={`price-${boxS}${doc.id}`}
      />
    )
  }

  return null
}

