import classnames from 'classnames'
import styles from './MovEntOtherHighlights.module.sass'
import { tr } from '../translator'

const allowed = [ 'area', 'marca', 'section', 'place' ]

export const MovEntOtherHighlights = ({
  otherHighlights
}) =>
  otherHighlights.map(hi => {
    const objPart = hi.attrPath.split('.')
    if (objPart && objPart.length == 2) {
      const attr = objPart[0]
      if (allowed.indexOf(attr) != -1) {
        const outerStyle = classnames(styles.bead, styles[attr])
        const attrLabel = tr(`activerecord.models.${attr}.one`)

        return (
          <div className={outerStyle}>
            <div className={styles.dark}>{attrLabel}</div>
            <div
              className={styles.light}
              dangerouslySetInnerHTML={{ __html: hi.contents[0] }}
            />
          </div>
        )
      } else
        return null
    } else
      return null
  })
