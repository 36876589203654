import classnames from 'classnames'

import { Icon } from '../bootstrap'
import { AreaLabel } from '../areas/AreaLabel'
import { fetchWithVetus } from '../utils/fetch'
import { AssociatedSearch } from '../ui/AssociatedSearch'
import { tr } from '../translator'

import styles from './AreaArtAssocSearch.module.sass'
import { isStore } from '../utils/special_areas'

export const AreaArtAssocSearch = ({
  areasOrg,
  isFactura,
  results,
  ...rest
}) => {
  const handleSearch = async (value) => {
    const params = {
      search: value,
      area_id: areasOrg.map(org => org.value),
      is_factura: isFactura
    }

    let results
    try {
      results = await fetchWithVetus('/area_arts/asoc_search.json', params)
    } catch(e) {
      console.error('Error getting asoc_search results', e)
    }

    const organized = results.reduce(({ normal, phantom }, cur) => {
      const isFromStore = isStore(cur.doc.area && cur.doc.area.name)

      if (isFromStore) {
        if (cur.doc.cantidad > 0)
          normal.push(cur)
        else
          phantom.push(cur)
      } else
        normal.push(cur)

      return { normal, phantom }
    }, { normal: [], phantom: [] })

    return organized.normal.concat(organized.phantom)
  }

  const renderer = (entry, Highlight) => {
    try {
      const { doc } = entry
      const isFromStore = isStore(doc.area && doc.area.name)
      const { cantidad } = doc

      const lineClasses = classnames(styles.line, {
        [styles.linePhantom]: isFromStore &&
                              !isFactura &&
                              cantidad <= 0
      })

      const stockAlert = isFromStore && doc.art && doc.art.stock_alert
      const amountClasses = classnames(styles.labelAmount, {
        [styles.bad]: isFromStore && !isFactura &&
                      stockAlert && cantidad < stockAlert,
        [styles.good]: isFromStore && !isFactura &&
                      stockAlert && cantidad >= stockAlert
      })

      if (doc.bring_to_area) {
        const label = tr('vetus.fact.bring_art_to_area', {
          art: doc.art.name
        })

        return (
          <>
            <Icon icon="arrow-down" />
            <span className={styles.labelText}>
              {label}
            </span>
          </>
        )
      } else {
        return (
          <div className={lineClasses}>
            <div>
              <AreaLabel areaName={doc.area.name} />
            </div>
            <span className={styles.labelText}>
              <Highlight />
            </span>
            {
              isFromStore && !isFactura &&
                <div className={amountClasses}>
                  {cantidad}
                </div>
            }
          </div>
        )
      }
    } catch (err) {
      console.error('fuckard', err)
    }
  }

  const keyer = (entry) => entry.doc && entry.doc.id
  const namer = (entry) => entry.doc.art && entry.doc.art.name
  const highlighter = entry => {
    const highlight = entry.hi
    if (highlight && ('art.name' in highlight)) {
      const toHighlight = highlight['art.name']
      if (toHighlight.length > 0) {
        const first = toHighlight[0]
        const regEx = new RegExp('</em> <em>', 'g')
        return first.replace(regEx, ' ')
      }
    }

    return entry.doc.art.name
  }

  return (
    <AssociatedSearch
      querier={handleSearch}
      renderer={renderer}
      keyer={keyer}
      namer={namer}
      highlighter={highlighter}
      results={results}
      {...rest}
    />
  )
}
