import { Table } from '../bootstrap'
import { PaymentTypeEdit } from './PaymentTypeEdit'
import { tr } from '../translator'

import styles from './PaymentEdit.module.sass'

export const PaymentEdit = ({
  payments,
  onChange,
  total
}) => {
  const paymentMethods = ['contado', 'card', 'transfer']

  return (
    <Table className={styles.container} condensed bordered>
      <tbody>
        {
          paymentMethods.map(kind =>
            <PaymentTypeEdit
              key={kind}
              type={kind}
              label={tr(`vetus.movimientos.all_${kind}`)}
              total={total}
              payments={payments}
              paymentMethods={paymentMethods}
              onChange={onChange}
            />
          )
        }
      </tbody>
    </Table>
  )
}
