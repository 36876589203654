import Select from 'react-select'
import { classFromAreaName } from '../utils/special_areas'
import chroma from 'chroma-js'

const storeColor = '#3a87ad'
const servicesColor = '#468847'

const specialStyles = {
  'label-info': {
    backgroundColor: chroma(storeColor).brighten(2).hex(),
    color: chroma(storeColor).darken(1).hex()
  },
  'label-success': {
    backgroundColor: chroma(servicesColor).brighten(2).hex(),
    color: chroma(servicesColor).darken(1).hex()
  }
}

function stylesFromData(styles, { data }, options = {}) {
  const specialClass = classFromAreaName(data.label)

  return {
    ...styles,
    ...specialStyles[specialClass]
  }
}

const colourStyles = {
  option: stylesFromData,
  multiValue: stylesFromData,
  multiValueLabel: stylesFromData,
  singleValue: (styles, { data }) => {
    return {
      ...stylesFromData(styles, { data }),
      fontSize: '85%',
      borderRadius: 4,
      padding: '2px 4px'
    }
  }
}

export const AreaSelect = props =>
  <Select
    {...props}
    styles={colourStyles}
  />
