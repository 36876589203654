import Flex from '@react-css/flex'
import { Well } from '../bootstrap'
import { DetailedPrice } from './DetailedPrice'
import { tr } from '../translator'
import { priceRead } from '../utils/money'

import styles from './DetailedPriceBoxable.module.sass'

export const DetailedPriceBoxable = ({
  subdiv,
  raw,
  procesado,
  ...rest
}) => {
  const rawNumber = priceRead(raw)
  const processedNumber = priceRead(procesado)
  const hasRaw = typeof rawNumber != 'undefined'
  const hasProcessed = typeof processedNumber != 'undefined'

  if (hasRaw || hasProcessed) {
    const unit = (
      <DetailedPrice
        {...rest}
        raw={raw}
        procesado={procesado}
      />
    )

    if (subdiv && subdiv > 1) {
      return (
        <Flex row noWrap>
          <Well label={tr('vetus.app.unit')}>
            {unit}
          </Well>
          <Well label={`× ${subdiv}`} className={styles.boxed} >
            <DetailedPrice
              {...rest}
              raw={raw * subdiv}
              procesado={procesado * subdiv}
            />
          </Well>
        </Flex>
      )
    } else {
      return unit
    }
  } else
    return null
}
