import { useRef } from 'react'
import { useDebouncer } from './useDebounce'

export function useAccumulativeDebouncer(f) {
  const accRef = useRef({})
  const callbackRef = useRef(f)

  const callbackIntercept = (data) => {
    callbackRef.current(data)
    accRef.current = {}
  }

  const { send } = useDebouncer(callbackIntercept)

  return {
    updateCallback(newCallback) {
      callbackRef.current = newCallback
    },

    send(data) {
      accRef.current = {
        ...accRef.current,
        ...data
      }
      send(accRef.current)
    }
  }
}
